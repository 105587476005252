<template>
    <tbody style="position: relative;">
        <tr class="new-row-animation event-overview-row">
            <td class="tx-mobile-flex-content">

                <div class="tx-table-cell-icon tx-table-cell-icon--service">
                    <event-overview-icon v-bind:event="eventName" />
                </div>

                <div class="tx-mobile-content">
                    <div class="tx-block-mobile-content__wrapper tx-block-mobile-content-swap-wrapper">
                        <div
                            class="tx-block-mobile-content__row tx-block-mobile-swap-items"
                            v-bind:class="type === 'NftItemTransfer' ? 'tx-block-nft-transfer' : ''"
                        >
                            <div class="tx-block-mobile-content__left">
                                <span class="muted tx-block-swap-name">
                                    {{ $t(`events.${eventName}`) }}
                                </span>
                            </div>
                            <div class="tx-block-mobile-content__right">
                                <event-overview-badge
                                    v-bind:type="type"
                                    v-bind:actionDetails="actionDetails"
                                />
                            </div>
                        </div>
                        <div class="tx-block-mobile-content__row">
                            <div class="tx-block-mobile-content__address">
                                <template v-if="type === 'JettonSwap' || type === 'NftItemTransfer' || type === 'JettonTransfer'|| type === 'TonTransfer'">
                                    <ui-address
                                        class="ui-address-event-overview"
                                        v-bind:address="processedAddressesTo"
                                        v-bind:alias="processedNameTo"
                                    />
                                    <div class="tx-table-action-icon" v-if="event">
                                        <arrow-right />
                                    </div>
                                    <ui-address v-if="event"
                                        class="ui-address-event-overview"
                                        v-bind:address="processedAddressesFrom"
                                        v-bind:alias="processedNameFrom"
                                    />
                                </template>
                                <template v-else>
                                    <ui-address
                                        class="ui-address-event-overview"
                                        v-bind:address="processedAddressesFrom"
                                        v-bind:alias="processedNameFrom"
                                    />
                                    <div class="tx-table-action-icon" v-if="event">
                                        <arrow-right />
                                    </div>
                                    <ui-address v-if="event"
                                        class="ui-address-event-overview"
                                        v-bind:address="processedAddressesTo"
                                        v-bind:alias="processedNameTo"
                                    />
                                </template>
                            </div>
                        </div>

                        <!-- <template v-if="type === 'SmartContractExec' && actionDetails?.operation">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span class="tx-mobile-comment__contents">{{ actionDetails?.operation }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="type === 'ContractDeploy' && actionDetails.interfaces.length > 0">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span class="tx-mobile-comment__contents" v-if="actionDetails.interfaces.length > 0">interfaces: {{ JSON.stringify(actionDetails.interfaces) }}</span>
                                    </div>
                                </div>
                            </div>
                        </template> -->

                        <template v-if="type === 'JettonTransfer' && actionDetails?.comment">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span class="tx-mobile-comment__contents">{{ actionDetails?.comment }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="type === 'NftItemTransfer' && actionDetails?.comment">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span class="tx-mobile-comment__contents">{{ actionDetails?.comment }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="type === 'TonTransfer' && actionDetails?.comment">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span class="tx-mobile-comment__contents">{{ actionDetails?.comment }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="actionDetails?.meta?.provider">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span class="tx-mobile-comment__contents">Provider: {{ actionDetails.meta.provider }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import ArrowRight from '@img/icons/tonscan/arrow-small-right.svg?vue';
import EventOverviewIcon from '~/components/tx/Tabs/EventOverviewIcon.vue';
import EventOverviewBadge from './EventOverviewBadge.vue';

export default {
    props: {
        action: Object,
        actionDetails: Object,
        type: String,
        event: Boolean,
    },

    computed: {
        eventName() {
            return this.actionDetails?.event === 'nft_purchase' ? 'nft_purchase' : this.type;
        },

        processedAddressesFrom() {
            return this.actionDetails.from;
        },

        processedNameFrom() {
            return this.actionDetails.source_alias;
        },

        processedAddressesTo() {
            return this.actionDetails.to;
        },

        processedNameTo() {
            return this.actionDetails.destination_alias;
        },
    },

    components: {
        EventOverviewIcon,
        ArrowRight,
        EventOverviewBadge,
    },
};
</script>

<style lang="scss">
.event-overview-row {
    .ui-inline-nft {
        padding: 0!important;
        margin: 0!important;
        border-radius: 8px!important;

        &:hover {
            background: var(--nft-preview-hover-background)!important;
            color: var(--nft-preview-hover-color)!important;
        }
    }
}

.tx-block-mobile-swap-items {
    align-items: center;
}

.tx-block-mobile-content-swap-wrapper {
    display: flex;
    flex-direction: column;
}

.tx-block-mobile-content__address {
    display: inline-flex;
    min-width: 0;
    flex-shrink: 1;
}

.tx-block-swap-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tx-block-nft-transfer {
    padding-bottom: 5px;
}

.ui-address-event-overview {
    max-width: 180px;
}

@media (max-width: 599px) {
    .tx-table-action-icon {
        margin: 0 5px;
    }
}
</style>
