<template>
    <div class="jetton-stats">
        <!-- <div class="jetton-stats-row" v-if="!emptyData">
            <div class="jetton-stats-row__name">{{ $t('jetton.volume') }}</div>
            <div class="jetton-stats-row__value">
                <span v-if="volume">
                    $ {{ volume }}
                </span>
                <span v-else class="skeleton">$12,345.67</span>
            </div>
        </div> -->

        <div class="jetton-stats-row" v-if="!emptyData">
            <div class="jetton-stats-row__name">{{ $t('jetton.market_cap') }}</div>
            <div class="jetton-stats-row__value">
                <span v-if="marketCap || marketCap === 0">
                    {{ currencySymbol }} {{ $ton(marketCap, decimals, true) }}
                </span>
                <span v-else class="skeleton">$12,345.678</span>
            </div>
        </div>

        <div class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('jetton.holders') }}</div>
            <div class="jetton-stats-row__value">
                <ui-link v-if="holders" v-bind:to="{ name: 'jetton', hash: '#holders', params: { address: address }}">{{ $round(holders) }}</ui-link>
                <span v-else class="skeleton">1234</span>
            </div>
        </div>

        <div class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('jetton.supply') }}</div>
            <div class="jetton-stats-row__value">
                <span v-if="total_supply !== undefined">
                    {{ $ton(total_supply, decimals, true) }} {{ symbol }}
                </span>
                <span v-else class="skeleton skeleton--inline">1000000 TOKENS</span>
            </div>
        </div>

        <div class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('jetton.mutable') }}</div>
            <div class="jetton-stats-row__value">
                <span v-if="is_mutable !== undefined">
                    {{ is_mutable ? $t('common.yes') : $t('common.no') }}
                </span>
                <span v-else class="skeleton skeleton--inline">Maybe</span>
            </div>
        </div>

        <div v-if="trust_score" class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('jetton.trust_score') }}</div>
            <div class="jetton-stats-row__value">
                <span v-if="is_mutable !== undefined">
                    {{ trust_score }}<span class="muted">/100</span>
                </span>
                <span v-else class="skeleton skeleton--inline">Maybe</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        address: String,
        volume: Number,
        total_supply: Number,
        holders: Number,
        price: Number,
        decimals: Number,
        emptyData: Boolean,
        symbol: String,
        marketCap: Number,
        is_mutable: Boolean,
        trust_score: Number,
    },

    computed: {
        currentCurrency() {
            return this.$store.state.exchangeRateCurrency;
        },
        currencySymbol() {
            const symbols = { usd: '$', rub: '₽', aed: 'د.إ' };
            return symbols[this.currentCurrency.toLowerCase()] || '';
        },
    },
};
</script>

<style lang="scss"></style>
