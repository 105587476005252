<template>
    <span class="ui-inline-jetton">
        <span class="ui-inline-jetton__value">
            {{$ton(meta.amount_in, meta.decimals_in, true)}}
        </span>
        <ui-link v-if="!meta?.symbol_in || meta.symbol_in !== 'TON'" class="ui-inline-jetton__link tx-table-jetton-link-standart" v-bind:to="{ name: 'jetton', params: { address: meta.jetton_in_address }}">
            {{ meta.symbol_in || shortenAddress(meta.jetton_in_address) }}
        </ui-link>
        <span v-else>
            TON
        </span>
        <IconArrowRight class="swap-icon-arrow" style="margin: 0 2px;"/>
        <span class="ui-inline-jetton__value" style="color: var(--green-bright)">
            {{$ton(meta.amount_out, meta.decimals_out, true)}}
        </span>
        <ui-link v-if="!meta?.symbol_out || meta.symbol_out !== 'TON'" style="color: var(--green-bright)" class="ui-inline-jetton__link tx-table-jetton-link-standart" v-bind:to="{ name: 'jetton', params: { address: meta.jetton_out_address }}">
            {{ meta.symbol_out || shortenAddress(meta.jetton_out_address) }}
        </ui-link>
        <span style="color: var(--green-bright)" v-else>
            TON
        </span>
    </span>
</template>

<script>
import IconArrowRight from '@img/icons/tonscan/arrow-small-right.svg?inline';

export default {
    props: ['meta'],
    methods: {
        shortenAddress(address) {
            return address.slice(0, 3) + '...' + address.slice(-3);
        },
    },

    components: {
        IconArrowRight,
    },
};
</script>

<style lang="scss">
.swap-icon-arrow {
    opacity: .7;
    width: 8px;
    height: 10px;
}
</style>
