<template>
    <div>
        <div class="widget-content">
            <div class="widget-wallet">
                <template v-if="whales.length">
                    <rich-wallet-item
                        v-for="(wallet, index) in whales"
                        v-bind:key="`widget-wallet-whale-${index}`"
                        v-bind:address="wallet.account"
                        v-bind:balance="wallet.balance"
                    />
                </template>
                <template v-else>
                    <rich-wallet-skeleton
                        v-for="i in 10"
                        v-bind:key="`widget-wallet-skeleton-${i}`"
                    />
                </template>
            </div>
        </div>
        <div class="mobile-tab-btn__container">
            <ui-link v-bind:to="{ name: 'whales' }" class="mobile-tab-btn">
                {{ $t('indexpage.mobile.show_all') }}
            </ui-link>
        </div>
    </div>
</template>

<script>
import { getTopBalances } from '~/api/toncenterV3.js';
import RichWalletItem from '../../RichWallets/RichWalletItem.vue';
import RichWalletSkeleton from '../../RichWallets/RichWalletSkeleton.vue';

export default {
    data() {
        return {
            whales: [],
        };
    },

    async created() {
        this.loadFromLocalStorage();
        const newWhales = await this.loadData();
        if (newWhales && newWhales.length > 0) {
            this.whales = newWhales;
            this.saveToLocalStorage(newWhales);
        }
    },

    methods: {
        async loadData() {
            try {
                const data = await getTopBalances({ limit: 10 });
                return data;
            } catch (error) {
                console.error('Error loading top balances:', error);
                return [];
            }
        },

        loadFromLocalStorage() {
            const storedWhales = localStorage.getItem('whales_mobile');
            if (storedWhales) {
                try {
                    const parsedWhales = JSON.parse(storedWhales);
                    setTimeout(() => {
                        this.whales = parsedWhales;
                    }, 300);
                } catch (error) {
                    console.error('Failed to parse stored whales:', error);
                    this.whales = [];
                }
            }
        },

        saveToLocalStorage(whales) {
            try {
                localStorage.setItem('whales_mobile', JSON.stringify(whales));
            } catch (error) {
                console.error('Failed to save whales to localStorage:', error);
            }
        },
    },

    components: {
        RichWalletItem,
        RichWalletSkeleton,
    },
};
</script>

<style lang="scss">
@media screen and (max-width: 600px) {
    .widget-wallet {
        padding: 20px 10px;
    }

    .widget-wallet-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 22px;
        font-size: 14px;
    }

    .widget-wallet-item:last-child {
        margin-bottom: 0;
    }

    .widget-wallet-data {
        display: flex;
        align-items: center;
    }

    .widget-wallet-balance {
        white-space: nowrap;
        font-weight: 500;
        margin-left: 24px;
    }

    .widget-wallet-icon {
        width: 18px;
        height: 18px;
        margin-left: 10px;
        fill: var(--body-muted-text-color);
        transform: translateY(-1px);
    }
}
</style>
