<template>
    <tbody style="position: relative;">
        <tr>
            <td>
                <div class="tx-table__cell">
                    <div class="tx-overview-action-type">
                        <div class="tx-overview-action-type__icon">
                            <event-overview-icon
                                v-bind:event="eventName"
                            />
                        </div>
                        {{ $t(`events.${eventName}`) }}
                    </div>
                </div>
            </td>
            <td>
                <div class="tx-table__cell">
                    <template v-if="type === 'jetton_swap' || type === 'nft_transfer' || type === 'jetton_transfer'">
                        <ui-address
                            v-bind:class="{ 'ui-address-event-overview': event || type === 'ton_transfer' }"
                            v-bind:address="processedAddressesTo"
                            v-bind:alias="processedNameTo"
                        />
                        <div class="tx-table-action-icon" v-if="event || type === 'ton_transfer'">
                            <arrow-right />
                        </div>
                        <ui-address v-if="event || type === 'ton_transfer'"
                            v-bind:class="{ 'ui-address-event-overview': event || type === 'ton_transfer' }"
                            class="ui-address-event-overview"
                            v-bind:address="processedAddressesFrom"
                            v-bind:alias="processedNameFrom"
                        />
                    </template>
                    <template v-else-if="type === 'nft_mint'">
                        <ui-address
                            v-bind:class="{ 'ui-address-event-overview': event || type === 'ton_transfer' }"
                            v-bind:address="processedAddressesFrom || processedAddressesTo"
                            v-bind:alias="processedNameFrom || processedAddressesTo"
                        />
                    </template>
                    <template v-else>
                        <ui-address
                            v-bind:class="{ 'ui-address-event-overview': event || type === 'ton_transfer' }"
                            v-bind:address="processedAddressesFrom"
                            v-bind:alias="processedNameFrom"
                        />
                        <div class="tx-table-action-icon" v-if="event || type === 'ton_transfer'">
                            <arrow-right />
                        </div>
                        <ui-address v-if="event || type === 'ton_transfer'"
                            v-bind:class="{ 'ui-address-event-overview': event || type === 'ton_transfer' }"
                            v-bind:address="processedAddressesTo"
                            v-bind:alias="processedNameTo"
                        />
                    </template>
                </div>
            </td>

            <td>
                <div class="tx-table__cell tx-table-payload">
                    <template v-if="type === 'call_contract'">
                        <span class="tx-table-payload-text muted">{{ actionDetails?.op }}</span>
                    </template>

                    <template v-else-if="type === 'contract_deploy'">
                        <span class="tx-table-payload-text muted">{{ actionDetails?.op }}</span>
                    </template>

                    <template v-else-if="type === 'jetton_transfer'">
                        <span class="tx-table-payload-text">{{ actionDetails?.message }}</span>
                    </template>

                    <template v-else-if="type === 'nft_transfer'">
                        <span class="tx-table-payload-text">{{ actionDetails?.message }}</span>
                    </template>

                    <template v-else-if="type === 'ton_transfer'">
                        <span class="tx-table-payload-text">{{ actionDetails?.message }}</span>
                    </template>

                    <template v-else-if="actionDetails?.meta?.provider">
                        <span class="tx-table-payload-text muted" style="text-transform: capitalize">Provider: {{ actionDetails.meta.provider }}</span>
                    </template>
                </div>
            </td>

            <td class="column-wide">
                <div class="tx-table__cell tx-table__cell--align-right">
                    <event-overview-badge
                        v-bind:type="type"
                        v-bind:actionDetails="actionDetails"
                    />
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import ArrowRight from '@img/icons/tonscan/arrow-small-right.svg?vue';
import EventOverviewBadge from './EventOverviewBadge.vue';
import EventOverviewIcon from './EventOverviewIcon.vue';

export default {
    props: {
        action: Object,
        actionDetails: Object,
        type: String,
        event: Boolean,
    },

    computed: {
        eventName() {
            return this.actionDetails?.event === 'nft_purchase' ? 'nft_purchase' : this.type;
        },

        processedAddressesFrom() {
            return this.actionDetails.from;
        },

        processedNameFrom() {
            return this.actionDetails.source_alias;
        },

        processedAddressesTo() {
            return this.actionDetails.to;
        },

        processedNameTo() {
            return this.actionDetails.destination_alias;
        },
    },

    components: {
        ArrowRight,
        EventOverviewBadge,
        EventOverviewIcon,
    },
};
</script>

<style lang="scss">
.tx-table-address-route {
    display: flex;
    align-items: center;
}

.tx-table-payload-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ui-address-event-overview {
    max-width: 180px;
}
@media (max-width: 599px) {
    .tx-table-action-icon {
        margin: 0 5px;
    }
}
</style>
