<template>
    <div class="card">
        <div class="card-title">
            <icon-tx-type-ordinary class="card-title__icon" />
            {{ $t('tx.title') }}
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>{{ $t('tx.account') }}</span>
            </div>
            <div class="card-row__value">
                <ui-copy-button v-bind:copy="transactionData.account"
                    v-bind:successMessage="$t('tx.hash_copy_success')">
                    <ui-address v-bind:address="transactionData.account" class="tx-status-preview__address" />
                </ui-copy-button>
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>{{ $t('tx.status') }}</span>
            </div>
            <div class="card-row__value">
                <!-- <span class="tx-status" v-bind:class="{
                    'tx-status--success': transactionData.description.compute_ph.success === true,
                    'tx-status--failed': !transactionData.description.compute_ph.success === false,
                }"> -->
                <span class="tx-status" v-bind:class="{
                    'tx-status--success': transactionData.description.compute_ph.success === true || transactionData.description.compute_ph.success === undefined,
                    'tx-status--failed': transactionData.description.compute_ph.success === false
                }">
                    <template v-if="transactionData.description.compute_ph.success === true || transactionData.description.compute_ph.success === undefined">
                        <icon-success class="tx-status__icon" />
                        {{ $t('tx.status_success') }}
                    </template>
                    <template v-else>
                        <icon-failed class="tx-status__icon" />
                        {{ $t('tx.status_failed') }}
                    </template>
                </span>
            </div>
        </div>
        <div class="card-row" v-if="transactionData.description.compute_ph.success === false">
            <div class="card-row__name" v-text="$t('tx.exit_codes')" />
            <div class="card-row__value">
                <div class="tx-flow-schematics">
                    <div class="tx-flow-schematics-step">
                        <div class="tx-flow-schematics-step__inner">
                            <header class="tx-flow-schematics-step__phase muted" v-text="$t('tx.compute_phase')" />
                            <div class="tx-flow-schematics-step__result">
                                <template v-if="transactionData.description.compute_ph?.exit_code === 0">
                                    {{ $t('tx.phase_aborted') }}
                                </template>
                                <template v-else-if="transactionData.description.compute_ph?.exit_code != null">
                                    {{ transactionData.description.compute_ph.exit_code }}
                                </template>
                                <template v-else>
                                    <span>{{ $t('tx.phase_aborted') }}</span>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="tx-flow-schematics-step">
                        <div class="tx-flow-schematics-step__inner">
                            <header class="tx-flow-schematics-step__phase muted" v-text="$t('tx.action_phase')" />
                            <div class="tx-flow-schematics-step__result">
                                <template>
                                    {{ $t('tx.phase_aborted') }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>{{ $t('tx.time') }}</span>
            </div>
            <div class="card-row__value">
                {{ formattedDate }}
                <span class="card-timeago">&mdash;</span>
                <ui-timeago class="card-timeago" v-bind:timestamp="transactionData.now * 1000" />
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>LT</span>
            </div>
            <div class="card-row__value">
                <ui-copy-button v-bind:copy="transactionData.lt"
                    v-bind:successMessage="$t('tx.lt_copy_success')">
                    {{ transactionData.lt }}
                </ui-copy-button>
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>{{ $t('tx.hash') }}</span>
            </div>
            <div class="card-row__value card-row__value--flex-row">
                <div class="card-row">
                    <div class="card-row__name">base64</div>
                    <div class="card-row__value">
                        <ui-copy-button v-bind:copy="transactionData.hash"
                            v-bind:successMessage="$t('tx.hash_copy_success')">
                            {{ transactionData.hash }}
                        </ui-copy-button>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-row__name">hex</div>
                    <div class="card-row__value">
                        <ui-copy-button style="text-transform: lowercase;" v-bind:copy="formattedHex"
                            v-bind:successMessage="$t('tx.hash_copy_success')">
                            {{ formattedHex }}
                        </ui-copy-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-row" v-if="hasAnyFees">
            <div class="card-row__name">
                <span>{{ $t('tx.developerData.fees') }}</span>
            </div>
            <div class="card-row__value card-row__value--flex-row">
                <div class="card-row" v-if="transactionData.total_fees">
                    <div class="card-row__name">{{ $t('tx.developerData.totalFee') }}</div>
                    <div class="card-row__value">
                        <span>{{ $fee(transactionData.total_fees) }} TON</span>
                    </div>
                </div>
                <div class="card-row" v-if="transactionData.description.compute_ph?.gas_fees">
                    <div class="card-row__name">{{ $t('tx.developerData.gasFee') }}</div>
                    <div class="card-row__value">
                        <span>{{ $fee(transactionData.description.compute_ph?.gas_fees) }} TON</span>
                    </div>
                </div>
                <div class="card-row" v-if="transactionData.description.action?.total_fwd_fees">
                    <div class="card-row__name">{{ $t('tx.developerData.fwdFee') }}</div>
                    <div class="card-row__value">
                        <span>{{ $fee(transactionData.description.action?.total_fwd_fees) }} TON</span>
                    </div>
                </div>
                <div class="card-row" v-if="transactionData.description.storage_ph?.storage_fees_collected">
                    <div class="card-row__name">{{ $t('tx.developerData.storageFee') }}</div>
                    <div class="card-row__value">
                        <span>{{ $fee(transactionData.description.storage_ph?.storage_fees_collected) }} TON</span>
                    </div>
                </div>
                <div class="card-row" v-if="transactionData.description.action?.total_action_fees">
                    <div class="card-row__name">{{ $t('tx.developerData.actionFee') }}</div>
                    <div class="card-row__value">
                        <span>{{ $fee(transactionData.description.action?.total_action_fees) }} TON</span>
                    </div>
                </div>
            </div>
            <!-- <div class="card-row__value card-row__value--flex-row">
                <div class="card-row">
                    <span>{{ $fee(totalFeesWithFwd) }} TON</span>
                </div>
            </div> -->
        </div>

        <div class="card-row" v-if="transactionData?.block_ref">
            <div class="card-row__name">
                <span>{{ $t('tx.developerData.blockId') }}</span>
            </div>
            <div class="card-row__value card-row__value--flex-row">
                <div class="card-row">
                    <a v-bind:href="`/block/${formattedBlockLink}`">
                        <span>{{ formattedBlock }}</span>
                    </a>
                </div>
            </div>
        </div>

        <div class="card-row">
            <div class="card-row__name">
                <span>{{ $t('tx.developerData.computePhase') }}</span>
            </div>
            <div class="card-row__value card-row__value--flex-row">
                <div class="card-row" v-if="transactionData.description.compute_ph.success !== undefined">
                    <div class="card-row__name">{{ $t('tx.developerData.success') }}</div>
                    <div class="card-row__value">
                        <span>{{ transactionData.description.compute_ph.success ? 'true' : 'false' }}</span>
                    </div>
                </div>
                <template v-if="transactionData.description.compute_ph?.exit_code >= 0">
                    <div class="card-row">
                        <div class="card-row__name">{{ $t('tx.developerData.exitCode') }}</div>
                        <div class="card-row__value">
                            <span>{{ transactionData.description.compute_ph?.exit_code }}</span>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="card-row">
                        <div class="card-row__name">{{ $t('tx.developerData.skipped') }}</div>
                        <div class="card-row__value">
                            <span>{{ transactionData.description.compute_ph.skipped ? 'True' : 'False' }}</span>
                        </div>
                    </div>
                    <!--<div class="card-row">-->
                    <!--    <div class="card-row__name">{{ $t('tx.developerData.skipReason') }}</div>-->
                    <!--    <div class="card-row__value">-->
                    <!--        <span>{{ transactionData.description.compute_phase.skip_reason }}</span>-->
                    <!--    </div>-->
                    <!--</div>-->
                </template>
                <div class="card-row" v-if="transactionData.description.compute_ph?.vm_steps">
                    <div class="card-row__name">{{ $t('tx.developerData.vmSteps') }}</div>
                    <div class="card-row__value">
                        <span>{{ transactionData.description.compute_ph.vm_steps }}</span>
                    </div>
                </div>
                <div class="card-row" v-if="transactionData.description.compute_ph?.gas_used">
                    <div class="card-row__name">{{ $t('tx.developerData.gasUsed') }}</div>
                    <div class="card-row__value">
                        <span>{{ transactionData.description.compute_ph.gas_used }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-row" v-if="hasActionPhaseParams">
            <div class="card-row__name">
                <span>{{ $t('tx.developerData.actionPhase') }}</span>
            </div>
            <div class="card-row__value card-row__value--flex-row">
                <div class="card-row" v-if="transactionData.description.action?.success">
                    <div class="card-row__name">{{ $t('tx.developerData.success') }}</div>
                    <div class="card-row__value">
                        <span>{{ transactionData.description.action.success ? 'true' : 'false' }}</span>
                    </div>
                </div>
                <div class="card-row" v-if="transactionData.description.action?.tot_actions">
                    <div class="card-row__name">{{ $t('tx.developerData.totalActions') }}</div>
                    <div class="card-row__value">
                        <span>{{ transactionData.description.action?.tot_actions }}</span>
                    </div>
                </div>

                <div class="card-row" v-if="transactionData.description.action?.skipped_actions >= 0">
                    <div class="card-row__name">{{ $t('tx.developerData.skippedActions') }}</div>
                    <div class="card-row__value">
                        <span>{{ transactionData.description.action.skipped_actions }}</span>
                    </div>
                </div>

                <div class="card-row" v-if="transactionData.description.action?.result_code >= 0">
                    <div class="card-row__name">{{ $t('tx.developerData.resultCode') }}</div>
                    <div class="card-row__value">
                        <span>{{ transactionData.description.action.result_code }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-row" v-bind:class="{ 'no-border': descriptionOpen }" style="cursor: pointer;" v-on:click="toggleDescription">
            <div class="card-row__name" v-text="$t('tx.description')" />
            <div class="card-row__value">
                <span>{{ $t('tx.json') }}</span>
            </div>
            <div class="card-row__chevron">
                <icon-expand v-bind:class="{ 'card-row__chevron-expand': descriptionOpen }" />
            </div>
        </div>
        <div class="tx-page-messages tx-page-json" v-if="descriptionOpen">
            <SourceJson v-bind:transactionDescription="transactionData.description" />
        </div>

        <div class="card-row" v-if="transactionData.in_msg.destination || transactionData.in_msg.source" style="border-bottom: 0; cursor: pointer;" v-on:click="toggleMsg">
            <div class="card-row__name" v-text="$t('tx.msgs')" />
            <div class="card-row__value">
                <span v-if="!transactionData.in_msg && !transactionData.out_msgs.length" v-text="$t('tx.msgs_empty')" />
                <span v-else v-text="$t('tx.msgs_count', [1, transactionData.out_msgs.length])" />
            </div>
            <div class="card-row__chevron">
                <icon-expand v-bind:class="{ 'card-row__chevron-expand': msgOpen }" />
            </div>
        </div>
        <div class="tx-page-messages" v-show="msgOpen">
            <div v-if="transactionData.in_msg.destination || transactionData.in_msg.source" class="tx-page-msg">
                <div class="card-row__name">
                    <span class="tx-table__badge tx-table__badge--in">IN</span>
                </div>
                <tx-msg class="tx-page-msg-details"
                    v-bind:source="transactionData.in_msg.source"
                    v-bind:destination="transactionData.in_msg.destination"
                    v-bind:value="transactionData.in_msg.value"
                    v-bind:fwdFee="transactionData.in_msg.fwd_fee"
                    v-bind:ihrFee="transactionData.in_msg.ihr_fee"
                    v-bind:createdLt="transactionData.in_msg.created_lt"
                    v-bind:hash="transactionData.in_msg.hash"
                    v-bind:comment="transactionData.in_msg.message_content?.decoded?.comment"
                    v-bind:op="transactionData.in_msg.opcode"
                    v-bind:createdAt="Number(transactionData.in_msg.created_at * 1000)"
                    v-bind:decodedBody="transactionData.in_msg?.message_content?.decoded" />
            </div>

            <div class="tx-page-msg" v-for="msg in transactionData.out_msgs" v-bind:key="msg.hash">
                <div class="card-row__name">
                    <span v-if="!msg.destination" class="tx-table__badge tx-table__badge--service"
                        v-text="$t('address.tx_table.log')" />

                    <span v-else class="tx-table__badge tx-table__badge--out" v-text="$t('address.tx_table.output')" />
                </div>

                <tx-msg class="tx-page-msg-details"
                    v-bind:source="msg?.source || null"
                    v-bind:destination="msg?.destination || null"
                    v-bind:value="msg.value"
                    v-bind:fwdFee="msg.fwd_fee"
                    v-bind:ihrFee="msg.ihr_fee"
                    v-bind:createdLt="msg.created_lt"
                    v-bind:hash="msg.hash"
                    v-bind:comment="msg.message_content?.decoded?.comment"
                    v-bind:op="msg.opcode"
                    v-bind:createdAt="Number(msg.created_at * 1000)"
                    v-bind:decodedBody="msg.message_content"/>
            </div>
        </div>
    </div>
</template>

<script>
import IconTxTypeOrdinary from '@primer/octicons/build/svg/git-commit-24.svg?inline';
import IconExpand from '@img/icons/tonscan/chevron-bottom-14.svg?vue';
import IconSuccess from '@primer/octicons/build/svg/check-circle-16.svg?inline';
import IconFailed from '@primer/octicons/build/svg/x-circle-16.svg?inline';
import { base64ToHex } from '~/utils.js';
import TxMsg from '../TxMsg.vue';
import SourceJson from './Json/SourceJson.vue';
import { formatTimestamp } from '../../../helpers';

export default {
    props: {
        transactionData: Object,
    },

    data() {
        return {
            msgOpen: true,
            descriptionOpen: false,
            hashHex: null,
        };
    },

    methods: {
        toggleMsg() {
            this.msgOpen = !this.msgOpen;
        },
        toggleDescription() {
            this.descriptionOpen = !this.descriptionOpen;
        },
    },

    computed: {
        formattedHex() {
            return base64ToHex(this.transactionData.hash);
        },

        formattedDate() {
            return formatTimestamp(this.transactionData.now * 1000);
        },

        totalFeesWithFwd() {
            const decimals = 9;
            const divisor = 10 ** decimals;

            const allFwdFees = this.transactionData.out_msgs.map(msg => Number(msg.fwd_fee) / divisor || 0);

            const sumFwdFee = allFwdFees.reduce((sum, fee) => sum + fee, 0);
            const totalFees = this.transactionData.total_fees / divisor;
            const total = totalFees + sumFwdFee;
            const totalAtomicUnits = Math.round(total * divisor);

            return totalAtomicUnits;
        },

        description() {
            return this.transactionData.description || {};
        },
        formattedDescription() {
            return JSON.stringify(this.description, null, 4);
        },

        hasAnyFees() {
            const data = this.transactionData.description;
            return data.total_fees
                || (data.compute_ph && data.compute_ph.gas_fees)
                || (data.action_ph && (data.action.fwd_fees || data.action.total_fees))
                || (data.storage_ph && data.storage_ph.fees_collected);
        },

        hasActionPhaseParams() {
            const ap = this.transactionData.description.action;
            return ap?.success !== undefined
                || ap?.total_actions !== undefined
                || ap?.skipped_actions !== undefined
                || ap?.result_code !== undefined;
        },

        formattedBlock() {
            const block = this.transactionData?.block_ref;
            if (!block) return '';
            return `(${block.workchain},${block.shard},${block.seqno})`;
        },

        formattedBlockLink() {
            const block = this.transactionData?.block_ref;
            if (!block) return '';
            return `${block.workchain}:${block.shard}:${block.seqno}`;
        },
    },

    components: {
        IconTxTypeOrdinary, TxMsg, IconExpand, IconSuccess, IconFailed, SourceJson,
    },
};
</script>

<style lang="scss">
.no-border {
    border-bottom: 0!important;
}
</style>
