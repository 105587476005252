var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.type === 'nft_transfer')?_c('div',{staticClass:"tx-row-msg-action-badge"},[_c('ui-link',{staticClass:"ui-inline-nft ui-inline-nft-mobile-swap",attrs:{"to":{ name: 'nft', params: { address: _vm.actionDetails.action.nft }}}},[_c('div',{staticClass:"ui-inline-nft__left"},[_c('div',{staticClass:"ui-inline-nft-image"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasError),expression:"!hasError"}],staticClass:"ui-inline-nft-image__img",attrs:{"src":_vm.actionDetails.meta?.image,"alt":""},on:{"error":function($event){_vm.hasError = true}}}),_vm._v(" "),_c('icon-nft',{staticClass:"ui-inline-nft-image__svg"})],1)]),_vm._v(" "),_c('div',{staticClass:"ui-inline-nft__title"},[_vm._v(_vm._s(_vm.actionDetails.meta.name || _vm.$t('common.nft_item')))])])],1):(_vm.type === 'jetton_burn')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v(_vm._s(_vm.formatSwap(_vm.actionDetails.action.amount, _vm.actionDetails.meta.decimals)))]),_vm._v(" "),_c('ui-link',{attrs:{"to":{ name: 'jetton', params: {
                address: _vm.canonizeAddress(_vm.actionDetails.meta.jetton_address),
            }
            }}},[_vm._v("\n            "+_vm._s(_vm.actionDetails.meta.symbol)+"\n        ")])],1):(_vm.type === 'jetton_transfer')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v(_vm._s(_vm.formatSwap(_vm.actionDetails.action.amount, _vm.actionDetails.meta.decimals)))]),_vm._v(" "),_c('ui-link',{attrs:{"to":{ name: 'jetton', params: {
                address: _vm.actionDetails.meta.jetton_address
            }
            }}},[_vm._v("\n            "+_vm._s(_vm.actionDetails.meta.symbol)+"\n        ")])],1):(_vm.type === 'jetton_swap')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('inline-swap',{attrs:{"meta":_vm.actionDetails.meta}})],1):(_vm.type === 'nft_mint')?[_c('ui-inline-nft-item',{attrs:{"address":_vm.actionDetails.nft,"image-src":_vm.actionDetails.meta.image,"nft-name":_vm.actionDetails.meta.name}})]:(_vm.type === 'renew_dns')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',[_vm._v(_vm._s(_vm.actionDetails?.meta?.domain))])]):(_vm.actionDetails?.amount)?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',[_vm._v(_vm._s(_vm.$ton(_vm.actionDetails.amount))+" TON")])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }