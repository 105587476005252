<template>
    <section>
        <div class="card jetton-info">
            <div class="jetton-meta">
                <div class="jetton-meta-header-items">
                    <div class="jetton-meta-header">
                        <div>
                            <ui-round-image v-if="address === 'EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE'" class="jetton-info-image" src="https://assets.dedust.io/images/dust.gif"/>
                            <ui-round-image v-else-if="address === 'EQAfzzRcfpnQeIUeNfXrn6p_TGhk-Cne7MnRvT93jry8HMJd'" class="jetton-info-image" src="https://i.imgur.com/c3Fdw1j.png"/>
                            <ui-round-image v-else-if="hasExternalAvatar" class="jetton-info-image" v-bind:src="imageLocal"/>
                            <ui-round-image v-else class="jetton-info-image" v-bind:src="imageLocal"/>
                        </div>
                        <div>
                            <p class="jetton-meta-header__name" v-if="nameLocal">{{ nameLocal }}<icon-verified v-if="is_verified" class="jetton-meta-header__icon-verified"/></p>
                            <p class="jetton-meta-header__name skeleton" v-else>NNNNNNNNNN</p>
                            <p class="jetton-meta-header__symbol" v-if="symbolLocal">{{ symbolLocal }}</p>
                            <p class="jetton-meta-header__symbol skeleton" v-else>NNNNNNNNNN</p>
                        </div>
                    </div>
                    <div class="jetton-meta-price" v-if="!emptyData">
                        <span v-if="price" class="jetton-meta-price__value">
                            <ui-format-price style="font-weight: 500" v-bind:price="fiatPrice" v-bind:symbol="currencySymbol" />
                        </span>
                        <span v-else class="jetton-meta-price__value skeleton">$1234</span>
                        <daily-change v-if="dailyChange" v-bind:daily_change="dailyChange" />
                    </div>
                </div>
                <div class="jetton-chart" v-show="isMobile || isTablet">
                    <div style="padding: 0 24px 0 16px; margin: 10px 0">
                        <chart-interval-selector v-model="interval" />
                    </div>
                    <div class="jetton-chart-container" v-if="!emptyData">
                        <jetton-price-chart v-bind:chartData="indexer" v-bind:interval="interval" />
                    </div>
                    <div class="jetton-chart-empty jetton-chart-empty__mobile" v-else>
                        <div class="jetton-chart-empty-content">
                            <div class="jetton-chart-empty-content__title">
                                <span>{{ $t('jetton.no_data') }}</span>
                            </div>
                            <div class="jetton-chart-empty-content__description">
                                <span>{{ $t('jetton.no_data_description') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="jetton-switcher">
                    <div class="jetton-switcher-button" v-bind:class="{ 'active': tabs === 'market' }" v-on:click="switchTab('market')">
                        <span>{{ $t('jetton.tab_market') }}</span>
                    </div>
                    <div class="jetton-switcher-button" v-bind:class="{ 'active': tabs === 'address' }" v-on:click="switchTab('address')">
                        <span>{{ $t('jetton.tab_address') }}</span>
                    </div>
                </div>

                <jetton-stats v-if="tabs === 'market'"
                    v-bind:address="address"
                    v-bind:volume="volume"
                    v-bind:total_supply="total_supply"
                    v-bind:holders="holders"
                    v-bind:price="price"
                    v-bind:decimals="decimals"
                    v-bind:emptyData="emptyData"
                    v-bind:symbol="symbol"
                    v-bind:marketCap="marketCap"
                    v-bind:is_mutable="is_mutable"
                    v-bind:trust_score="trustScore"
                />

                <jetton-address v-if="tabs === 'address'"
                    v-bind:admin_address="admin_address"
                    v-bind:is_mutable="is_mutable"
                    v-bind:metadata_url="metadata_url"
                    v-bind:address="address"
                    v-bind:balance="balance"
                />

                <div
                    v-if="description"
                    class="jetton-description-container"
                    v-bind:class="{'jetton-description-hide': descriptionHide && description.length > 125}"
                    v-on:click="descriptionHide =false"
                >
                    <div class="jetton-description">
                        {{ description }}
                    </div>
                    <div class="jetton-hide-expander">
                        <span>{{ $t('jetton.show_more') }}</span>
                    </div>
                </div>

                <div>
                    <a v-if="isMobile || isTablet" class="dyor-logo-jettons-mobile" target="_blank" href="https://dyor.io/">
                        <span style="margin-left: auto">{{ $t('jetton_filters.dyor.data_from' )}} DYOR.io</span>
                        <icon-title-help class="dyor-logo-jettons-mobile__icon"/>
                    </a>
                </div>
            </div>
            <div class="jetton-chart" v-show="!isMobile && !isTablet">
                <div class="jetton-chart__header">
                    <div class="jetton-chart__header--title">{{ $t('jetton.market_data') }}</div>
                    <div><chart-interval-selector v-model="interval" /></div>
                </div>
                <div class="jetton-chart-container" v-if="!emptyData">
                    <jetton-price-chart v-bind:chartData="indexer" v-bind:interval="interval" />
                </div>
                <div class="jetton-chart-empty" v-else>
                    <div class="jetton-chart-empty-content">
                        <div class="jetton-chart-empty-content__title">
                            <span>{{ $t('jetton.no_data') }}</span>
                        </div>
                        <div class="jetton-chart-empty-content__description">
                            <span>{{ $t('jetton.no_data_description') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <jetton-tabs
            v-bind:address="address"
            v-bind:supply="total_supply"
            v-bind:symbol="displayedTicker"
            v-bind:dyor-id="dyorId"
            v-bind:decimals="decimals"
        >
            <template v-slot:customFilter>
                <dyor-logo v-if="!isMobile && !isTablet && $store.state.filterShow" />
            </template>
        </jetton-tabs>
    </section>
</template>

<script>
// import UiMetadataSource from '~/components/UiMetadataSource.vue';
import { getJettonChart } from '~/api/tonapi';
import { checkAddress } from '~/api/extenderContracts';
import { formattedPriceChart, getDaysAgoTimestamp } from '~/helpers';
import IconVerified from '@img/icons/tonscan/apps/verified.svg?vue';
import IconTitleHelp from '@primer/octicons/build/svg/info-16.svg?vue';
import DyorLogo from '~/components/market/DyorLogo.vue';
import { getJettonInfoDyor } from '~/api/dyor';
import JettonTabs from './JettonTabs.vue';
import JettonStats from './JettonStatsRows.vue';
import JettonAddress from './JettonAddressRows.vue';
import DailyChange from './DailyChangeIndicator.vue';
import JettonPriceChart from './JettonPriceChart.vue';
import ChartIntervalSelector from './ChartIntervalSelector.vue';

export default {
    props: {
        address: String,
        admin_address: [String, null],
        total_supply: Number,
        is_mutable: Boolean,
        metadata_url: [String, null],
        name: String,
        description: String,
        balance: Number,
        symbol: String,
        image_data: String,
        decimals: Number,
        holders: Number,
        jettonMarket: Array,
        volume: Number,
        image: {
            type: Object,
            default: () => ({}),
        },
        imageTA: String,
    },

    data() {
        return {
            myAddress: undefined,
            indexer: [],
            interval: 1,
            price: null,
            tabs: 'market',
            descriptionHide: true,
            emptyData: false,
            dailyChange: null,
            jetton: null,
            imageLocal: undefined,
            nameLocal: undefined,
            symbolLocal: undefined,
            is_verified: false,
            dyorId: undefined,
            trustScore: undefined,
        };
    },

    mounted() {
        this.loadLocal();
    },

    computed: {
        displayedTicker() {
            if (this.symbol?.match(/^[a-z]{2,16}$/i)) {
                return this.symbol;
            }

            if (this.address === 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs') {
                return 'USD₮';
            }

            return this.$t('jetton.tokens');
        },
        hasExternalAvatar() {
            return Boolean(this.image?.original);
        },
        currentCurrency() {
            return this.$store.state.exchangeRateCurrency;
        },
        currencySymbol() {
            const symbols = { usd: '$', rub: '₽', aed: 'د.إ' };
            return symbols[this.currentCurrency.toLowerCase()] || '';
        },
        marketCap() {
            if (this.price > 0) return this.fiatPrice * this.total_supply;
            return undefined;
        },
        fiatPrice() {
            return this.$ton(this.price, 0) * this.$store.state.exchangeRate;
        },
    },

    created() {
        this.fetchJettonData();
    },

    watch: {
        interval: 'fetchChartData',
        address() {
            this.reset();
            this.loadLocal();
            this.fetchJettonData();
        },
        imageTA() {
            this.loadLocal();
        },
        name() {
            this.loadLocal();
        },
        symbol() {
            this.loadLocal();
        },
    },

    methods: {
        formattedPriceChart,
        reset() {
            this.myAddress = undefined;
            this.indexer = [];
            this.interval = 1;
            this.price = null;
            this.tabs = 'market';
            this.descriptionHide = true;
            this.emptyData = false;
            this.dailyChange = null;
            this.jetton = null;
            this.imageLocal = undefined;
            this.nameLocal = undefined;
            this.symbolLocal = undefined;
            this.is_verified = false;
            this.dyorId = undefined;
            this.trustScore = undefined;
        },
        loadLocal() {
            this.imageLocal = this.imageTA;
            this.nameLocal = this.name;
            this.symbolLocal = this.symbol;
        },
        switchTab(tab) {
            this.tabs = tab;
        },
        async fetchJettonData() {
            try {
                const dyorInfo = await getJettonInfoDyor(this.address);
                this.price = dyorInfo.cachedJetton.price;
                this.dailyChange = dyorInfo.cachedJetton.priceChange24h;
                this.dyorId = dyorInfo?.jetton?.id;
                this.trustScore = dyorInfo?.cachedJetton?.trustScore;
            } catch (e) {
                console.error(e);
                this.dyorId = null;
                this.trustScore = undefined;
                const currentHash = this.$route.hash;
                this.emptyData = true;

                if (currentHash !== '#transactions') {
                    this.$router.push({
                        name: 'jetton', hash: '#transactions',
                        params: { address: this.address, dontRefresh: true },
                    });
                }
            }

            try {
                const startDate = getDaysAgoTimestamp(this.interval);
                const chartData = await getJettonChart({
                    token: this.address,
                    currency: this.currentCurrency.toUpperCase(),
                    start_date: startDate,
                    end_date: Math.floor(Date.now() / 1000),
                });

                if (chartData.points.length > 0) {
                    this.indexer = chartData.points.slice(1).sort((a, b) => a[0] - b[0]);
                } else {
                    this.emptyData = true;
                }
            } catch (error) {
                this.emptyData = true;
                this.tabs = 'address';
                console.error(error);
            }

            try {
                const addressInfo = await checkAddress(this.address);
                this.is_verified = addressInfo?.meta?.is_verified;
            } catch (error) {
                console.error(error);
            }
        },

        async fetchChartData() {
            try {
                this.indexer = [];

                const startDate = getDaysAgoTimestamp(this.interval);
                const chartData = await getJettonChart({
                    token: this.address,
                    currency: this.currentCurrency.toUpperCase(),
                    start_date: startDate,
                    end_date: Math.floor(Date.now() / 1000),
                });

                if (chartData.points.length > 0) {
                    this.indexer = chartData.points.slice(1).sort((a, b) => a[0] - b[0]);
                }
            } catch (error) {
                this.emptyData = true;
                console.error(error);
            }
        },

        getSwapUrl(address) {
            return `https://ton.diamonds/dex/swap?ref=EQDCH6vT0MvVp0bBYNjoONpkgb51NMPNOJXFQWG54XoIAs5Y&inputToken=TON&outputToken=${address}`;
        },
    },

    components: {
        // UiMetadataSource,
        JettonTabs,
        JettonStats,
        JettonAddress,
        DailyChange,
        JettonPriceChart,
        ChartIntervalSelector,
        DyorLogo,
        IconVerified,
        IconTitleHelp,
    },
};
</script>

<style lang="scss">
.jetton-chart-empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--card-border-color);
    border-radius: 12px;
    &__mobile {
        height: 230px;
        border: 0;
    }
}
.jetton-chart-empty-content {
    &__title {
        font-size: 23px;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
    }
    &__description {
        text-align: center;
        color: var(--body-muted-text-color);
    }
}
.jetton-hide-expander {
    color: var(--blue-bright);
    cursor: pointer;
    font-weight: 500;
    margin-bottom: -10px;
    margin-top: -10px;
    z-index: 900;
    position: absolute;
    bottom: 11px;
    right: 18px;
    padding: 2px 5px;
    background: linear-gradient(to left,var(--card-background) 20%,transparent);
    display: none;
}

.jetton-description-hide .jetton-hide-expander {
    display: block;
}

.jetton-hide-expander span {
    position: relative;
    z-index: 10;
}

.jetton-hide-expander:hover {
    text-decoration: underline;
}

.jetton-description-container {
    position: relative;
}

.jetton-description-hide .jetton-description {
    max-height: 21px;
    overflow: hidden;
    position: relative;
}

.jetton-hide-expander:after {
    background: linear-gradient(to left,var(--card-background) 70%,transparent);
    bottom: 0;
    content: "";
    height: 100%;
    width: 160px;
    pointer-events: none;
    position: absolute;
    right: 0;
}

.jetton-info-image {
    border-radius: 50%;
    margin: 0 14px 0 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    align-self: flex-start;
}

.jetton-info {
    display: flex;
    padding: 0;
    flex-direction: row!important;
}

.jetton-meta {
    width: 320px;
    border-right: 1px solid var(--card-border-color);
    // padding: 16px;
    box-sizing: border-box;
}

.jetton-chart {
    flex-grow: 1;
    padding: 25px 15px 10px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
        margin-bottom: 25px;
        padding-right: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--title {
            font-weight: 500;
            font-size: 16px;
            text-transform: uppercase;
        }
    }
}

.jetton-meta-header {
    display: flex;
    align-items: center;
    padding: 0 16px 0 16px;

    p {
        margin: 0;
    }

    &__name {
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px!important;
    }

    &__symbol {
        font-size: 16px;
        color: var(--body-muted-text-color);
    }

    &__icon-verified {
        margin-left: 6px;
        color: var(--app-icon-verified-color);
        transform: translateY(2px);
        width: 14px;
        height: 14px;
    }
}

.jetton-meta-price {
    display: flex;
    align-items: center;
    padding: 0 16px;

    &__value {
        font-size: 24px;
        font-weight: 500;
        margin-right: 15px;
    }
}

.jetton-stats {
    padding: 0 16px;
}

.jetton-stats-row {
    width: 100%;
    border-bottom: 1px solid var(--card-border-color);
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    &__name {
        font-weight: 500;
    }

    &__value > a {
        //font-weight: 500;
    }
}

.jetton-description {
    color: var(--body-muted-text-color);
    line-height: 20px;
    font-size: 14px;
    margin: 16px 16px 16px 16px;
}

.jetton-switcher {
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 3px;
    background: var(--chart-interval-selector-background);
    color: var(--chart-interval-selector-color);
    text-transform: none;
    margin-left: 16px;
    margin-right: 16px; margin-bottom: 7px;
}

.jetton-switcher-button {
    width: 50%;
    text-align: center;
    padding: 5px 12px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

.jetton-switcher .active {
    background: var(--chart-interval-selector-item-background);
    color: var(--chart-interval-selector-item-active-color);
}

.jetton-meta-header-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 20px;
    padding-top: 16px;
}

@media screen and (max-width: 991px) {
    // .jetton-description-hide .jetton-hide-expander {
    //     display: none;
    // }

    .jetton-description-hide .jetton-description {
        max-height: 20px;
    }

    .jetton-meta {
        border: none;
        width: 100%;
    }

    .jetton-info {
        flex-direction: column!important;
    }

    .jetton-chart {
        padding: 0;
        margin-bottom: 10px;
    }

    .jetton-swap {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
        max-width: none;
    }
}

@media screen and (max-width: 768px) {
    .jetton-meta-header-items {
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 5px;
    }
}

.dyor-logo-jettons-mobile{
    color: inherit;
    font-size: 13px;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 6px 0;
    margin: 8px 16px 8px 0;
    text-transform: none;
    font-weight: normal;
    opacity: .65;
    transition: .12s all ease;

    &:hover {
        opacity: 1;
        color: var(--blue-bright);
        text-decoration: none;
    }
    &__icon {
        fill: currentColor;
        width: 16px;
        height: 16px;
        margin-left: 5px;
    }
}
</style>
