import { render, staticRenderFns } from "./EventOverviewBadge.vue?vue&type=template&id=53ac18fb"
import script from "./EventOverviewBadge.vue?vue&type=script&lang=js"
export * from "./EventOverviewBadge.vue?vue&type=script&lang=js"
import style0 from "./EventOverviewBadge.vue?vue&type=style&index=0&id=53ac18fb&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports