var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-link',{staticClass:"user-nft",attrs:{"to":{
        name: 'nft',
        params: {
            address: _vm.address,
            skeletonHint: 'item',
        },
    }}},[_c('div',{staticClass:"user-nft__image"},[(_vm.content.image)?[(!_vm.imageError)?_c('img',{staticClass:"user-nft__image__img",attrs:{"decoding":"async","src":_vm.content.image,"loading":_vm.idx > 4 ? 'lazy' : 'eager',"alt":_vm.content?.name},on:{"error":function($event){_vm.imageError = true}}}):(_vm.imageError)?_c('div',{staticClass:"user-nft__image__broken"},[_c('icon-broken')],1):_vm._e()]:_c('div',{staticClass:"user-nft__image__broken"},[_c('icon-broken')],1)],2),_vm._v(" "),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"user-nft__footer"},[(_vm.collection?.name && _vm.collectionAddress)?_c('ui-link',{staticClass:"user-nft__collection",attrs:{"to":{
                name: 'nft',
                params: {
                    address: _vm.collectionAddress,
                    skeletonHint: 'collection',
                },
            }}},[_c('span',{staticClass:"user-nft__collection__text",domProps:{"textContent":_vm._s(_vm.collection.name)}})]):_c('span',{staticClass:"user-nft__collection",domProps:{"textContent":_vm._s(_vm.$t('address.nfts.no_collection'))}}),_vm._v(" "),(_vm.itemName)?_c('h3',{staticClass:"user-nft__name",domProps:{"textContent":_vm._s(_vm.itemName)}}):_c('h3',{staticClass:"user-nft__name muted",domProps:{"textContent":_vm._s(_vm.$t('address.nfts.no_name'))}})],1)
}]

export { render, staticRenderFns }