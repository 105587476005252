import axios from 'axios';
import { TONCENTER_API_KEY, TONCENTER_V3_API_ENDPOINT } from '~/config.js';
import { hexToBase64 } from '~/utils.js';
import { canonizeAddress } from '~/tonweb';
import opCodesDictionary from '../../json/traceLabels.json';

/* eslint camelcase: "off", func-names: "off" */

// Disable headers if api key is not set. Otherwise
// axios will make a pre-flight request:
const httpHeaders = TONCENTER_API_KEY
    ? { 'X-API-Key': TONCENTER_API_KEY }
    : undefined;

const http = axios.create({
    baseURL: TONCENTER_V3_API_ENDPOINT,
    headers: httpHeaders,
});

/**
 * This function returns last blocks sorted by time.
 *
 * @param  {Numer} options.wc
 * @param  {Number} options.limit
 * @param  {Number} options.offset
 * @param  {Number} options.startUtime
 * @param  {Number} options.endUtime
 * @return {Promise<Array>}
 */
export const getPreviousBlocks = async function ({ wc, limit, offset, startUtime, endUtime, afterLt } = {}) {
    const { data: { blocks: result } } = await http.get('/blocks', {
        params: {
            offset, limit,
            after_lt: afterLt,
            workchain: wc,
            start_utime: startUtime,
            end_utime: endUtime,
            sort: 'desc',
        },
    });

    result.forEach((block) => {
        /* eslint no-param-reassign: "off" */
        block.root_hash_hex = block.root_hash;
        block.root_hash = hexToBase64(block.root_hash);
    });

    return result.map(Object.freeze);
};

export const getBlockHeader = async function ({ workchain, shard, seqno }) {
    const query = { workchain, shard, seqno, limit: 1 };

    const { data: { blocks } } = await http.get('/blocks', { params: query });

    return blocks[0];
};

const getSourceAndDestination = function (msg, address, hash, addressBook) {
    const from = msg.source ? addressBook[msg.source].user_friendly : null;
    const to = msg.destination ? addressBook[msg.destination].user_friendly : null;

    return {
        from, to,
        is_out: address === from,
        amount: msg.value || null,
        created_at: msg.created_at,
        hash,
    };
};

/**
 * @param  {Number} options.wc
 * @param  {Number} options.startUtime
 * @param  {Number} options.endUtime
 * @return {Promise<Array>}
 */
export const getAllTransactions = async function ({ wc, limit, startUtime, endUtime } = {}) {
    const { data } = await http.get('/transactions', {
        params: {
            limit,
            workchain: wc,
            start_utime: startUtime,
            end_utime: endUtime,
            sort: 'desc',
        },
    });

    const result = data.transactions;
    const addressBook = data.address_book;

    const transactions = result.map((tx) => {
        const address = tx.account;
        const hash = tx.hash;

        const is_service = !tx.in_msg && tx.out_msgs.length === 0;
        const is_external = tx.out_msgs.length === 0 && !tx.in_msg?.source && tx.in_msg?.destination === address;
        let msg = undefined;

        if (is_service) {
            msg = {
                source: address,
                destination: null,
                created_at: tx.created_at || tx.now,
            };
        } else if (tx.out_msgs.length > 0) {
            msg = tx.out_msgs.at(-1);
        } else {
            msg = tx.in_msg;
        }

        const sourceAndDestination = getSourceAndDestination(msg, address, hash, addressBook);

        sourceAndDestination.is_service = is_service;
        sourceAndDestination.is_external = is_external;
        sourceAndDestination.created_at = tx.now;

        return sourceAndDestination;
    });

    return transactions.sort((a, b) => b.created_at - a.created_at).map(Object.freeze);
};

/**
 * @see https://api.toncenter.com/index/#/default/get_top_accounts_by_balance_v1_topAccountsByBalance_get
 * @param  {Number} options.limit
 * @param  {Number} options.offset
 * @return {Promise<Array>}
 */
export const getTopBalances = async function ({ limit, offset } = {}) {
    const { data } = await http.get('/topAccountsByBalance', {
        params: { limit, offset },
    });

    return data.map(item => ({
        ...item,
        account: canonizeAddress(item.account),
    }));
};

/**
 * @param  {String} hash
 * @return {Promise<Object>}
 */
export const getTransactionByHash = async function (hash) {
    const { data } = await http.get('/transactions', { params: { hash } });

    return Object.freeze(data);
};

/**
 * @param  {String} hash
 * @return {Promise<Object>}
 */
export const getTransactionByInMsgHash = async function (hash) {
    const { data } = await http.get('/transactionsByMessage', {
        params: {
            msg_hash: hash,
            direction: 'in',
        },
    });

    return Object.freeze(data);
};

/**
 * @param  {String} hash
 * @return {Promise<Object>}
 */
export const getTransactionByHashOrInMessageHash = async function (hash) {
    const byHash = await getTransactionByHash(hash);

    if (byHash?.transactions?.length > 0) {
        return byHash;
    }

    /* eslint no-return-await: "off" */
    return await getTransactionByInMsgHash(hash);
};

/**
 * @param  {Number} options.workchain
 * @param  {Number} options.shard
 * @param  {Number} options.seqno
 * @return {Promise<Object>}
 */
export const getBlockTransactions = async function ({ workchain, shard, seqno, offset, limit = 40 }) {
    const query = { workchain, shard, seqno, offset, limit, sort: 'asc' };

    const { data: result } = await http.get('/transactions', { params: query });

    // Convert address hex notation to base64:
    result.transactions.forEach((tx) => {
        tx.account = result.address_book[tx.account].user_friendly; /* eslint no-param-reassign: "off" */
    });

    return result;
};

/**
 * @param  {Number} options.seqno
 * @return {Promise<Object>}
 */
export const getShards = async function ({ seqno }) {
    const { data: { blocks: result } } = await http.get('masterchainBlockShardState', { params: { seqno } });

    const shards = result.filter(block => block.workchain >= 0).reverse();

    return shards;
};

/**
 * @return {Promise<Object>}
 */
export const getLastBlock = async function () {
    const { data: result } = await http.get('masterchainInfo');

    return Object.freeze(result.last);
};

/**
 * This function extracts the data, that may be unique for every msg
 *
 * @param  {Object} msg
 * @return {Object}
 */
const parseMessageData = function extractMessageDetails(msg, addressBook) {
    const from = addressBook[msg.source]?.user_friendly || null;
    const to = addressBook[msg.destination]?.user_friendly || null;

    const message = msg?.message_content?.decoded?.type === 'text_comment' ? msg?.message_content?.decoded?.comment : undefined;

    return { from, to,
        message,
        amount: msg.value,
        op: msg.opcode ? `0x${(msg.opcode >>> 0).toString(16)}` : null, // eslint-disable-line no-bitwise
        is_bounced: msg.bounced === true,
    };
};

/**
 * @return {Promise<Object>}
 */
export const getTransactionsV3 = async function (address, { limit, offset }) {
    const { data: result } = await http.get('transactions', {
        params: {
            account: address,
            limit,
            offset,
        },
    });

    const addressBook = result?.address_book;
    const transactions = result?.transactions;

    const groups = [];

    transactions.forEach((tx) => {
        const is_service = !tx.in_msg && tx.out_msgs.length === 0;

        const is_external = tx.out_msgs.length === 0
            && !tx.in_msg?.source
            && addressBook[tx.in_msg?.destination] === address;

        const newWalletTxSuccess = tx.description?.action?.result_code === undefined && tx.description?.compute_ph?.exit_code === undefined;
        const executionSuccess = tx.description.action?.action_result_code !== null && parseInt(tx.description?.action?.result_code, 10) <= 1;

        const is_success = newWalletTxSuccess || executionSuccess;
        const exit_code = is_success
            ? tx.description?.action?.result_code
            : tx.description?.compute_ph?.exit_code;

        const txDetails = {
            address,
            hash: tx.hash,
            fee: tx.total_fees,
            lt: tx.lt,
            timestamp: parseInt(tx.now + '000', 10),
            output_count: tx.out_msgs.length,
            exit_code: exit_code || 0,
            messages: [],
        };

        const msgDetails = {
            is_service, is_external, is_success,
            is_aggregated: false,
        };

        // Don't display long message list (e.g. multisends), show aggregated info instead:
        if (tx.out_msgs.length > 10) {
            const aggregatedAmount = tx.out_msgs.reduce((total, outMsg) => parseInt(outMsg.value, 10) + total, 0);
            txDetails.messages.push({ ...msgDetails,
                amount: aggregatedAmount,
                is_aggregated: true,
                is_bounced: tx.out_msgs.some(msg => msg.bounced),
                from: address,
                to: 'multiple destinations', // must be truthy to indicate that we do have the destination
            });

        // Otherwise push out_msgs to the list in chronological (reverse) order:
        } else {
            tx.out_msgs.reverse().forEach((outMsg) => {
                txDetails.messages.push({ ...msgDetails, ...parseMessageData(outMsg, addressBook) });
            });
        }

        // Then push the input message:
        if (tx.in_msg?.source) {
            txDetails.messages.push({ ...msgDetails, ...parseMessageData(tx.in_msg, addressBook) });
        }

        // Special case when there're neither out_msgs, nor in_msg (like in system contract):
        if (is_service) {
            txDetails.messages.push({ ...msgDetails,
                from: address,
                to: null,
            });
        }

        // Special case for external messages (e. g. when activating wallet):
        if (is_external) {
            txDetails.messages.push({ ...msgDetails,
                from: null,
                to: address,
            });
        }

        for (let i = 0; i < txDetails.messages.length; i += 1) {
            Object.freeze(txDetails.messages[i]);
        }

        groups.push(Object.freeze(txDetails));
    });

    return groups;
};

export const getCodeHash = async function (address) {
    let code_hash = undefined;

    const query = {
        account: address,
        sort: 'asc',
    };

    const { data: result } = await http.get('/transactions', { params: query });

    result?.transactions?.forEach((tx) => {
        if (tx?.account_state_after?.code_hash && !code_hash) {
            code_hash = tx.account_state_after.code_hash;
        }
    });

    return code_hash;
};

export const getJettonInfo = async (address) => {
    try {
        const response = await http.get('jetton/masters', { params: { address } });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const getWalletInfo = async function (address) {
    const { data: response } = await http.get(`wallet?address=${address}`);

    return response;
};

export const getAccountInfo = async function (address) {
    const { data: response } = await http.get(`account?address=${address}`);

    return response;
};

export const getAccountStates = async function (address) {
    const { data: response } = await http.get(`accountStates?address=${address}&include_boc=false`);

    return response;
};

export const generateMessage = function (eventAction, addressBook, msgAccount, metadata = []) {
    const eventName = eventAction.type;
    const eventObj = eventAction.details;

    const messages = {
        from: undefined,
        to: undefined,
        action: undefined,
        event: eventName,
        meta: undefined,
        source_alias: undefined,
        destination_alias: undefined,
        message: eventObj?.comment || null,
        is_external: eventName === 'Unknown',
        is_success: eventAction.success,
        is_swapped: eventName === 'jetton_swap',
        is_aggregated: false,
        is_bounced: false,
        is_service: false,
        op: eventObj?.opcode,
    };

    function getAddressbook(address) {
        if (address && addressBook[address]) {
            return addressBook[address].user_friendly;
        }
        return null;
    }

    function getAlias(address) {
        if (address && addressBook[address]) {
            return addressBook[address].domain;
        }
        return undefined;
    }

    switch (eventName) {
        case 'ton_transfer': {
            messages.from = getAddressbook(eventObj.source);
            messages.to = getAddressbook(eventObj.destination);
            messages.source_alias = getAlias(eventObj.source);
            messages.destination_alias = getAlias(eventObj.destination);
            messages.amount = eventObj.value;
            messages.event = msgAccount === getAddressbook(eventObj.source)
                ? 'sent_ton'
                : 'received_ton';
            break;
        }

        case 'subscribe': {
            messages.from = getAddressbook(eventObj.subscriber);
            messages.to = getAddressbook(eventObj.beneficiary);
            messages.source_alias = getAlias(eventObj.subscriber);
            messages.destination_alias = getAlias(eventObj.beneficiary);
            messages.amount = eventObj.amount;
            break;
        }

        case 'unsubscribe': {
            messages.from = getAddressbook(eventObj.subscriber);
            messages.to = getAddressbook(eventObj.beneficiary);
            messages.source_alias = getAlias(eventObj.subscriber);
            messages.destination_alias = getAlias(eventObj.beneficiary);
            break;
        }

        case 'call_contract': {
            messages.tonAmount = eventObj.value || 0;
            messages.from = getAddressbook(eventObj?.source);
            messages.to = getAddressbook(eventObj.destination);
            messages.source_alias = getAlias(eventObj?.source);
            messages.destination_alias = getAlias(eventObj.destination);
            messages.amount = eventObj.value;
            break;
        }

        case 'nft_mint': {
            messages.action = Object.freeze({
                type: 'nft:transfer_action',
                nft: getAddressbook(eventObj.nft_item),
            });
            messages.meta = Object.freeze({
                name: metadata[eventObj.nft_item]?.token_info[0]?.name,
                image: metadata[eventObj.nft_item]?.token_info[0]?.image,
                collection: metadata[eventObj.nft_collection]?.token_info[0]?.name,
            });
            messages.from = getAddressbook(eventObj?.source);
            messages.to = getAddressbook(eventObj.owner);
            messages.source_alias = getAlias(eventObj?.source);
            messages.destination_alias = getAlias(eventObj.owner);
            messages.source_type = 'wallet';
            messages.destination_type = 'wallet';
            messages.event = 'deploy_nft';
            break;
        }

        case 'nft_transfer': {
            messages.action = Object.freeze({
                type: 'nft:transfer_action',
                nft: getAddressbook(eventObj.nft_item),
            });
            messages.from = getAddressbook(eventObj?.old_owner);
            messages.to = getAddressbook(eventObj?.new_owner);
            messages.source_alias = getAlias(eventObj?.old_owner);
            messages.destination_alias = getAlias(eventObj?.new_owner);
            messages.source_type = 'wallet';
            messages.destination_type = 'wallet';
            messages.meta = Object.freeze({
                name: metadata[eventObj.nft_item]?.token_info[0]?.name,
                image: metadata[eventObj.nft_item]?.token_info[0]?.image,
                collection: metadata[eventObj.nft_collection]?.token_info[0]?.name,
            });
            messages.event = 'nft_transfer';
            if (eventObj.is_purchase) {
                messages.event = 'nft_purchase';
            }
            break;
        }

        case 'jetton_transfer': {
            messages.from = getAddressbook(eventObj.sender);
            messages.to = getAddressbook(eventObj.receiver);
            messages.source_alias = getAlias(eventObj.sender);
            messages.destination_alias = getAlias(eventObj.receiver);
            messages.action = Object.freeze({
                type: msgAccount === getAddressbook(eventObj?.sender) ? 'jetton:transfer' : 'jetton:transfer_notification',
                amount: eventObj.amount,
                sender: getAddressbook(eventObj.sender),
                destination: messages.to = getAddressbook(eventObj.receiver),
            });
            messages.meta = Object.freeze({
                symbol: metadata[eventObj.asset]?.token_info[0]?.symbol || getAddressbook(eventObj.asset),
                jetton_address: getAddressbook(eventObj.asset),
                decimals: Number(metadata[eventObj.asset]?.token_info[0]?.extra?.decimals) || 9,
            });
            messages.event = msgAccount === getAddressbook(eventObj?.sender)
                ? 'sent_jetton'
                : 'received_jetton';
            break;
        }

        case 'jetton_burn': {
            messages.from = getAddressbook(eventObj.owner);
            messages.to = getAddressbook(eventObj.asset);
            messages.source_alias = getAlias(eventObj.owner);
            messages.destination_alias = getAlias(eventObj.asset);
            messages.amount = eventObj.amount;
            messages.meta = Object.freeze({
                symbol: metadata[eventObj.asset]?.token_info[0]?.symbol || getAddressbook(eventObj.asset),
                jetton: getAddressbook(eventObj.asset),
                jetton_address: getAddressbook(eventObj.asset),
                decimals: Number(metadata[eventObj.asset]?.token_info[0]?.extra?.decimals) || 9,
            });
            messages.action = Object.freeze({
                type: 'jetton:burn',
                amount: eventObj.amount,
            });
            break;
        }

        case 'jetton_swap': {
            messages.amount_in = eventObj.dex_incoming_transfer.amount;
            messages.amount_out = eventObj.dex_outgoing_transfer.amount;
            messages.dex = eventObj.dex;
            messages.action = Object.freeze({
                type: 'jetton:swap',
            });
            messages.meta = Object.freeze({
                jetton_in_address: getAddressbook(eventObj.asset_in) || 'Ef9VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVbxn',
                amount_in: eventObj.dex_incoming_transfer.amount,
                symbol_in: metadata[eventObj.asset_in]?.token_info[0]?.symbol,
                decimals_in: Number(metadata[eventObj.asset_in]?.token_info[0]?.extra?.decimals) || 9,
                jetton_out_address: getAddressbook(eventObj.asset_out) || 'Ef9VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVbxn',
                amount_out: eventObj.dex_outgoing_transfer.amount,
                symbol_out: metadata[eventObj.asset_out]?.token_info[0]?.symbol,
                decimals_out: Number(metadata[eventObj.asset_out]?.token_info[0]?.extra?.decimals) || 9,
            });
            messages.from = getAddressbook(eventObj.sender);
            messages.to = getAddressbook(eventObj.dex_incoming_transfer.destination);
            messages.source_alias = getAlias(eventObj.sender);
            messages.destination_alias = getAlias(eventObj.dex_incoming_transfer.destination);
            break;
        }

        case 'contract_deploy':
            messages.from = getAddressbook(eventObj?.source);
            messages.to = getAddressbook(eventObj.destination);
            messages.source_alias = getAlias(eventObj?.source);
            messages.destination_alias = getAlias(eventObj.destination);
            messages.amount = eventObj.value;

            break;

        case 'renew_dns':
            messages.from = getAddressbook(eventObj.source);
            messages.to = getAddressbook(eventObj.asset);
            messages.source_alias = getAlias(eventObj.source);
            messages.destination_alias = getAlias(eventObj.asset);
            messages.meta = Object.freeze({
                domain: metadata[eventObj.asset]?.token_info[0]?.extra?.domain,
            });
            break;

        case 'change_dns':
            messages.from = getAddressbook(eventObj.source);
            messages.to = getAddressbook(eventObj.asset);
            messages.source_alias = getAlias(eventObj.source);
            messages.destination_alias = getAlias(eventObj.asset);
            break;

        case 'delete_dns':
            messages.from = getAddressbook(eventObj.source);
            messages.to = getAddressbook(eventObj.asset);
            messages.source_alias = getAlias(eventObj.source);
            messages.destination_alias = getAlias(eventObj.asset);
            break;

        case 'auction_bid':
            messages.from = getAddressbook(eventObj.bidder);
            messages.to = getAddressbook(eventObj?.auction || eventObj.nft_item);
            messages.source_alias = getAlias(eventObj.bidder);
            messages.destination_alias = getAlias(eventObj?.auction || eventObj.nft_item);
            messages.amount = eventObj.amount;

            messages.meta = Object.freeze({
                domain: metadata[eventObj.nft_item]?.token_info[0]?.extra?.domain,
                symbol: 'TON',
            });

            break;

        case 'election_deposit':
            messages.from = getAddressbook(eventObj.stake_holder);
            messages.to = 'Ef8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzM0vF'; // Always elector
            messages.source_alias = getAlias(eventObj.stake_holder);
            messages.destination_alias = getAlias('Ef8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzM0vF');
            messages.amount = eventObj.amount;
            messages.meta = Object.freeze({
                provider: eventObj?.provider,
            });
            break;

        case 'election_recover':
            messages.from = 'Ef8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzM0vF'; // Always elector
            messages.to = getAddressbook(eventObj.stake_holder);
            messages.source_alias = getAlias('Ef8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzM0vF');
            messages.destination_alias = getAlias(eventObj.stake_holder);
            messages.amount = eventObj.amount;
            messages.meta = Object.freeze({
                provider: eventObj?.provider,
            });
            break;

        case 'stake_withdrawal':
            messages.from = getAddressbook(eventObj.stake_holder);
            messages.to = getAddressbook(eventObj.pool || eventObj.payout_nft);
            messages.source_alias = getAlias(eventObj.stake_holder);
            messages.destination_alias = getAlias(eventObj.pool || eventObj.payout_nft);
            messages.amount = eventObj.amount;
            messages.meta = Object.freeze({
                provider: eventObj?.provider,
            });
            break;

        case 'stake_withdrawal_request':
            messages.from = getAddressbook(eventObj.stake_holder);
            messages.to = getAddressbook(eventObj.pool);
            messages.source_alias = getAlias(eventObj.stake_holder);
            messages.destination_alias = getAlias(eventObj.pool);
            messages.amount = eventObj.amount;
            messages.meta = Object.freeze({
                provider: eventObj?.provider,
            });
            break;

        case 'stake_deposit':
            messages.from = getAddressbook(eventObj.stake_holder);
            messages.to = getAddressbook(eventObj.pool);
            messages.source_alias = getAlias(eventObj.stake_holder);
            messages.destination_alias = getAlias(eventObj.pool);
            messages.amount = eventObj.amount;
            messages.meta = Object.freeze({
                provider: eventObj?.provider,
            });
            break;

        case 'jetton_mint':
            messages.from = getAddressbook(eventObj.asset);
            messages.to = getAddressbook(eventObj.receiver);
            messages.source_alias = getAlias(eventObj.asset);
            messages.destination_alias = getAlias(eventObj.receiver);
            messages.amount = eventObj.amount;

            messages.meta = Object.freeze({
                symbol: metadata[eventObj.asset]?.token_info[0]?.symbol || getAddressbook(eventObj.asset),
                jetton_address: getAddressbook(eventObj.asset),
                decimals: Number(metadata[eventObj.asset]?.token_info[0]?.extra?.decimals) || 9,
            });

            break;

        default:
            // For test only:
            // In the case if we have an event that was not found previously - we can print it in alert

            // console.log('Found an unknown event: ' + eventName);

            // Return undefined if there was an unknown event so we can delete it from messages
            return undefined;
    }

    return messages;
};

export const getToncenterActions = async function (account, { tx_hash, msg_hash, trace_id, start_utime, end_utime, limit, offset }) {
    const params = {
        account,
        tx_hash,
        msg_hash,
        trace_id,
        start_utime,
        end_utime,
        limit,
        offset,
        sort: 'desc',
    };

    const { data: response } = await http.get('/actions', {
        params,
    });

    const actions = response.actions.map(action => Object.freeze({
        address: account,
        fee: null,
        hash: action.trace_id,
        lt: action.end_lt,
        timestamp: action.end_utime * 1000,
        messages: [generateMessage(action, response.address_book, account, response.metadata)],
        action: 'ok',
    }));

    return actions;
};

// TODO: remove
// Temporary function. After toncenter's API will be proxied on backend the function should be removed and 'getAccountInfo' used instead
export const getAccountInfoSha256 = async function (address) {
    const { data: response } = await axios.get(`https://jetton-index.tonscan.org/contract/code?address=${address}`);

    return response;
};

export const getPackOfBalances = async function (addresses = []) {
    const { data: response } = await http.get('walletStates', {
        params: {
            address: addresses,
        },
    });

    const balances = {};

    response.wallets.forEach((addr) => {
        balances[response.address_book[addr.address].user_friendly] = addr.balance;
    });

    return balances;
};

export const getNFTsByOwnerAddress = async function (address, limit = 12, offset = 0) {
    const { data: response } = await http.get('nft/items', {
        params: {
            owner_address: address,
            limit,
            offset,
        },
    });

    return response;
};

export const getJettonsByOwnerAddress = async function (address, limit = 20, offset = 0) {
    const { data: response } = await http.get('/jetton/wallets', {
        params: {
            owner_address: address,
            exclude_zero_balance: true,
            limit,
            offset,
        },
    });

    const testnet_order_list = [
        '0:B84D0D9581B9CCF5935DB4F4E0E07B482DF4D6871E3995ED725993DF3D0A80E8', // USDT
        '0:119AE171343EC283E1495593EAD040616FF60BCF399C42A7AFA6EF3CE7B56181', // NOT
        '0:636F07A74BD6FAE6DE6A1B9034E966446D8F4841C81F3369B4C2EA4472392912', // DOGS
    ].reverse();

    // TODO: проверка на тестнет/майннет
    if (response?.jetton_wallets) {
        response.jetton_wallets.sort((b, a) => testnet_order_list.indexOf(a.jetton) - testnet_order_list.indexOf(b.jetton));
    }

    return response;
};

export const getTransactionTraceV3 = async function getEventsByAccount(address, msge) {
    const queryParam = msge ? `msg_hash=${address}` : `tx_hash=${address}`;
    let response = await http.get(`traces?${queryParam}`, {
        params: {
            include_actions: true,
        },
    });

    if (response?.data?.traces?.length === 0 && !msge) {
        response = await http.get(`traces?msg_hash=${address}`, {
            params: {
                include_actions: true,
            },
        });
    }

    const { trace: rootTrace, transactions } = response.data.traces[0];
    const addressBook = response.data.address_book;

    function getUserFriendlyAddress(addr) {
        return addressBook[addr]?.user_friendly || addr;
    }

    let currentId = 1;
    const connections = [];

    function findTransactionByHash(tx_hash) {
        return transactions[tx_hash];
    }

    function processTransactionFields(transaction) {
        return {
            ...transaction,
            account: getUserFriendlyAddress(transaction?.account),
            in_msg: {
                ...transaction.in_msg,
                source: transaction.in_msg?.source ? getUserFriendlyAddress(transaction.in_msg.source) : null,
                destination: transaction.in_msg?.destination ? getUserFriendlyAddress(transaction.in_msg.destination) : null,
            },
            out_msgs: transaction.out_msgs?.map(msg => ({
                ...msg,
                source: msg.source ? getUserFriendlyAddress(msg.source) : null,
                destination: msg.destination ? getUserFriendlyAddress(msg.destination) : null,
            })) || [],
        };
    }

    function countAllDescendants(node) {
        if (!node.children || node.children.length === 0) {
            return 0;
        }
        return node.children.reduce((sum, child) => sum + countAllDescendants(child) + 1, 0);
    }

    function sortChildrenCentered(children) {
        if (children.length <= 1) return children;

        const sorted = [...children].sort((a, b) => a.childrenCount - b.childrenCount);

        const middleIndex = Math.floor((sorted.length - 1) / 2);
        const middleElement = sorted.shift();
        const finalSorted = [...sorted.slice(0, middleIndex), middleElement, ...sorted.slice(middleIndex)];

        return finalSorted;
    }

    function getLabelForOpCode(opCode) {
        if (opCode === null) {
            return 'Transfer';
        }

        return opCodesDictionary[opCode] || opCode;
    }

    function processNode(node, parentName = null) {
        let transaction = findTransactionByHash(node.tx_hash);
        transaction = processTransactionFields(transaction);

        const canonize = getUserFriendlyAddress(transaction.account);
        const name = `${canonize.slice(0, 2)}...${canonize.slice(-4)}`;
        const id = currentId;
        currentId += 1;
        const children = node.children ? Object.values(node.children).map(child => processNode(child, name)) : [];

        if (parentName) {
            connections.push({
                source: parentName,
                target: name,
                label: 'Oops',
                value: transaction.in_msg.value,
            });
        }

        const childrenWithCount = children.map(child => ({
            ...child,
            childrenCount: countAllDescendants(child),
        }));

        const sortedChildren = sortChildrenCentered(childrenWithCount);

        return {
            id,
            name,
            status: transaction.description.compute_ph.success,
            transaction,
            interfaces: 'jetton',
            address: getUserFriendlyAddress(transaction.account),
            label: getLabelForOpCode(transaction.in_msg.opcode),
            value: transaction.in_msg.value,
            opCode: transaction.in_msg.opcode,
            children: sortedChildren,
        };
    }

    const trace = processNode(rootTrace);

    const series = {
        traceId: response.data.traces[0].trace_id,
        timeStart: response.data.traces[0].start_utime,
    };

    response.data.traces[0].actions.forEach((action) => {
        action.simple_preview = generateMessage(action, response.data.address_book, address, response.data.metadata);
    });

    return {
        events: response.data.traces[0],
        trace,
        connections,
        series,
    };
};
