<template>
    <div class="footer">
        <div class="footer-container">
            <div class="footer-nav">
                <a href="https://t.me/catchain" target="_blank" class="footer-nav-link">
                    <span>{{ $t('footer.news') }}</span>
                </a>
                <a href="https://t.me/faqbot" target="_blank" class="footer-nav-link">
                    <span>{{ $t('footer.report_issue') }}</span>
                </a>
                <a href="https://github.com/catchain/tonscan" class="footer-nav-link">
                    <span>GitHub</span>
                </a>
                <a href="https://toncenter.com" class="footer-nav-link">
                    <span>API</span>
                </a>
                <ui-link v-if="showAds" v-bind:to="{ name: 'advertise' }">
                    {{ $t('footer.ads') }}
                </ui-link>
            </div>
        </div>
    </div>
</template>

<script>
import { IS_TESTNET } from '~/config';

export default {
    data() {
        return {
            showAds: true,
        };
    },

    mounted() {
        if (IS_TESTNET) this.showAds = false;
    },
};
</script>

<style lang="scss">

.footer {
    padding: 25px 10px 0 10px;
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
}

.footer-container {
    flex-grow: 1;
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-nav {
    display: flex;
    gap: 20px;
    height: 100%;
    a {
        color: var(--body-muted-text-color);
        font-size: 14px;
        text-decoration: none !important;
        user-select: none;
    }
    a:hover {
        color: var(--body-text-color);
    }
}

.footer-nav a {
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.footer-copyright {
    color: var(--body-muted-text-color);
    font-size: 14px;
}
</style>
