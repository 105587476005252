<template>
    <div>
        <tx-events v-bind:address="address" v-if="currentComponent === 'tx-events'" v-on:lastActivityUpdate="handleNestedActivityUpdate" />

        <tx-history v-bind:address="address" v-if="currentComponent === 'tx-history'" />

        <tx-simple v-bind:address="address" v-if="currentComponent === 'tx-simple'" />
    </div>
</template>

<script>
import TxEvents from './TxEvents.vue';
import TxHistory from './TxHistory.vue';
import TxSimple from './TxSimple.vue';

export default {
    props: {
        address: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            currentComponent: undefined,
        };
    },

    components: {
        TxEvents,
        TxHistory,
        TxSimple,
    },

    mounted() {
        this.updateCurrentComponent(this.$store.state.transactionViewData);
    },

    watch: {
        '$store.state.transactionViewData': function (newVal) {
            this.updateCurrentComponent(newVal);
        },
    },

    methods: {
        // updateFilterShow(value) {
        //     this.$store.commit('updateFilterShow', value);
        // },

        // handleTabNameChange(tabName) {
        //     if (tabName === 'events') {
        //         this.updateFilterShow(true);
        //     } else {
        //         this.updateFilterShow(false);
        //     }
        // },

        updateCurrentComponent(value) {
            if (value === 'alternative') {
                this.currentComponent = 'tx-events';
            } else if (value === 'professional') {
                this.currentComponent = 'tx-history';
            } else if (value === 'simple') {
                this.currentComponent = 'tx-simple';
            }
        },

        handleNestedActivityUpdate(data) {
            this.$emit('lastActivityUpdate', data);
        },
    },
};
</script>
