<template>
    <section>
        <div class="tx-row-msg-action-badge" v-if="type === 'nft_transfer'">
            <ui-link v-bind:to="{ name: 'nft', params: { address: actionDetails.action.nft }}" class="ui-inline-nft ui-inline-nft-mobile-swap">
                <div class="ui-inline-nft__left">
                    <div class="ui-inline-nft-image">
                        <img v-bind:src="actionDetails.meta?.image" v-show="!hasError" v-on:error="hasError = true" alt="" class="ui-inline-nft-image__img">
                        <icon-nft class="ui-inline-nft-image__svg"/>
                    </div>
                </div>
                <div class="ui-inline-nft__title">{{ actionDetails.meta.name || $t('common.nft_item') }}</div>
            </ui-link>
        </div>
        <div class="tx-row-tx-event-action-badge" v-else-if="type === 'jetton_burn'">
            <span class="ui-inline-jetton__value">{{ formatSwap(actionDetails.action.amount, actionDetails.meta.decimals) }}</span>
            <ui-link
                v-bind:to="{ name: 'jetton', params: {
                    address: canonizeAddress(actionDetails.meta.jetton_address),
                }
                }">
                {{ actionDetails.meta.symbol }}
            </ui-link>
        </div>
        <div class="tx-row-tx-event-action-badge" v-else-if="type === 'jetton_transfer'">
            <span class="ui-inline-jetton__value">{{ formatSwap(actionDetails.action.amount, actionDetails.meta.decimals) }}</span>
            <ui-link
                v-bind:to="{ name: 'jetton', params: {
                    address: actionDetails.meta.jetton_address
                }
                }">
                {{ actionDetails.meta.symbol }}
            </ui-link>
        </div>
        <div class="tx-row-tx-event-action-badge" v-else-if="type === 'jetton_swap'">
            <inline-swap v-bind:meta="actionDetails.meta" />
        </div>
        <template v-else-if="type === 'nft_mint'">
            <ui-inline-nft-item
                v-bind:address="actionDetails.nft"
                v-bind:image-src="actionDetails.meta.image"
                v-bind:nft-name="actionDetails.meta.name"
            />
        </template>
        <div class="tx-row-tx-event-action-badge" v-else-if="type === 'renew_dns'">
            <span>{{ actionDetails?.meta?.domain }}</span>
        </div>
        <div class="tx-row-tx-event-action-badge" v-else-if="actionDetails?.amount">
            <span>{{$ton(actionDetails.amount)}} TON</span>
        </div>
    </section>
</template>

<script>
import IconNft from '@img/icons/tonscan/nft-14.svg?inline';
import { canonizeAddress } from '~/tonweb.js';
import { getNftItemPreviewUrl } from '~/api/extenderContracts.js';
import { formatSwap } from '~/helpers.js';
import UiInlineNftItem from '~/components/address/TxRowInlineNft.vue';
import InlineSwap from '~/components/address/TxRowInlineSwap.vue';

export default {
    props: {
        type: String,
        actionDetails: Object,
    },

    data() {
        return {
            address: null,
            imageSrc: null,
            hasError: false,
        };
    },

    methods: {
        canonizeAddress,
        formatSwap,
        setFriendlyAddress() {
            if (this.actionDetails && this.actionDetails.nft) {
                const canonizedAddress = canonizeAddress(this.actionDetails.nft, {
                    type: undefined,
                });

                if (canonizedAddress) {
                    this.address = canonizedAddress;
                }
            }
        },

        updateImageSrc() {
            if (this.address) {
                this.imageSrc = getNftItemPreviewUrl(this.address);
            }
        },
    },

    watch: {
        address(newAddress) {
            if (newAddress) {
                this.updateImageSrc();
            }
        },
    },

    created() {
        this.setFriendlyAddress();
    },

    components: {
        InlineSwap,
        UiInlineNftItem,
        IconNft,
    },
};
</script>

<style lang="scss">
.tx-row-tx-event-action-badge {
    display: flex;
    align-items: center;
}

@media (max-width: 599px) {
    .ui-inline-nft-mobile-swap {
        transform: none!important;
        max-height: 30px;
    }
}
</style>
