<template>
    <section>
        <section v-if="wallet.invalid">
            <div class="alert" v-text="$t('error.invalid_address')"/>
        </section>

        <section v-show="!wallet.invalid">
            <div class="card">
                <div class="card-row" v-if="$store.getters.getLocalAddressbookName(address) || addressMeta?.name">
                    <div class="card-row__name" v-text="$t('address.info.name')"/>
                    <div class="card-row__value">
                        <div>
                            <span>{{ $store.getters.getLocalAddressbookName(address) || addressMeta?.name }}</span>
                            <!--<bookmark-icon style="bottom: -4px" v-if="$store.getters.getLocalAddressbookName(address)" />-->
                        </div>
                    </div>
                </div>

                <div class="card-row" v-if="contractExtendedInfo && contractExtendedInfo?.type === 'dns_domain'">
                    <div class="card-row__name" v-text="$t('address.contract_type.domain_name')"/>
                    <div class="card-row__value">
                        <span class="card-domain-container">
                            <contract-info-domain
                                v-bind:auction="contractExtendedInfo.domain.auction"
                                v-bind:domainName="contractExtendedInfo.domain.domain_name"
                                v-bind:resolvedValue="contractExtendedInfo.domain.resolved_value"
                                v-bind:auctionActive="contractExtendedInfo.domain.auction_active"/>
                            <a v-bind:href="`http://${contractExtendedInfo.domain.domain_name}.ton`" target="_blank">
                                <icon-domain class="card-domain-icon card-main-qr-button" />
                            </a>
                        </span>
                    </div>
                </div>

                <div class="card-row" v-if="contractExtendedInfo && contractExtendedInfo.type === 'telegram_number'">
                    <div class="card-row__name" v-text="$t('address.contract_type.number')"/>
                    <div class="card-row__value">
                        <ui-copy-button v-bind:copy="'+' + contractExtendedInfo.telegram_number.phone_number">
                            {{contractExtendedInfo.telegram_number.phone_number_formatted}}
                        </ui-copy-button>
                    </div>
                </div>

                <div class="card-row" v-if="contractExtendedInfo && contractExtendedInfo.type === 'telegram_username'">
                    <div class="card-row__name" v-text="$t('address.contract_type.username')"/>
                    <div class="card-row__value">
                        <span class="card-domain-container">
                            <ui-copy-button v-bind:copy="contractExtendedInfo.telegram_username.username + '.t.me'">
                                @{{contractExtendedInfo.telegram_username.username}}
                            </ui-copy-button>
                            <a v-bind:href="`https://t.me/${contractExtendedInfo.telegram_username.username}`" target="_blank">
                                <icon-domain class="card-domain-icon card-main-qr-button" />
                            </a>
                        </span>
                    </div>
                </div>

                <div class="card-row">
                    <div class="card-row__name" v-text="$t('address.info.address')"/>
                    <div class="card-row__value">
                        <ui-copy-button class="card-main-address"
                            v-bind:successMessage="$t('address.info.copy_success')"
                            v-bind:copy="address"
                        >
                            {{address}}
                        </ui-copy-button>

                        <span class="card-main-qr-button" v-on:click="qrModalVisible = true">
                            <icon-qr class="card-main-qr-button__icon"/>
                        </span>
                    </div>
                </div>

                <div class="card-row">
                    <div class="card-row__name" v-text="$t('address.info.balance')"/>
                    <div class="card-row__value" v-if="wallet.balance == '0' || wallet.balance">
                        {{$ton(wallet.balance)}}
                        <span v-text="addressMeta.tonIcon || 'TON'" title="TON"/>

                        <template v-if="wallet.balance != '0'">
                            <span v-if="$store.state.exchangeRate" style="color: #717579">
                                ≈ <ui-fiat v-bind:tonValue="wallet.balance"/>
                            </span>
                        </template>
                    </div>
                    <div v-else class="card-row__value">
                        <span class="skeleton">00000 TON ≈ 00000 USD</span>
                    </div>
                </div>

                <div class="card-row" v-if="!topJettonsEmpty">
                    <div class="card-row__name" v-text="$t('address.info.assets')"/>
                    <div v-if="topJettons" class="card-row__value top-user-jettons">
                        <ui-round-image v-for="jetton in topJettons.jetton_wallets" class="jetton-list__icon top-user-jettons__img" size="tiny" v-bind:src="topJettons.metadata[jetton.jetton].token_info[0].image" v-bind:key="jetton.jetton" />
                        <div class="top-user-jettons__text">
                            {{ topJettonBalance(topJettons.jetton_wallets[0].balance, topJettons.metadata[topJettons.jetton_wallets[0].jetton].token_info[0].decimals) }} {{ topJettons.metadata[topJettons.jetton_wallets[0].jetton].token_info[0].symbol }}
                        </div>
                        <ui-link v-bind:to="{ name: 'address', hash: '#tokens', params: { address: this.address, dontRefresh: true }}">
                            {{ $t('address.info.and_more') }}
                        </ui-link>
                    </div>
                    <div v-else class="card-row__value">
                        <span class="skeleton">1234561234 jettons</span>
                    </div>
                </div>

                <div class="card-row">
                    <div class="card-row__name" v-text="$t('address.info.details')"/>
                    <div class="card-row__value">
                        <div class="card-row__value-tags">
                            <div
                                class="card-row__value-tag-item"
                                v-on:mouseover="showTooltip.state = true"
                                v-on:mouseleave="showTooltip.state = false"
                            >
                                <div v-if="wallet?.status === undefined || isSuspended === undefined" class="card-row__value-tag card-row__value-skeleton skeleton">
                                    <span>NNNNN</span>
                                </div>
                                <div v-else-if="wallet?.status === 'active'" class="card-row__value-tag card-row__value-tag-green">
                                    <span>{{ $t('address.info.type_active') }}</span>
                                </div>
                                <div v-else-if="wallet?.status === 'frozen'" class="card-row__value-tag card-row__value-tag-blue">
                                    <span>{{ $t('address.info.type_frozen') }}</span>
                                </div>
                                <ui-link v-bind:to="{name: 'suspended'}" v-else-if="isSuspended" class="card-row__value-tag card-row__value-tag-orange router-link__no-style">
                                    <span>{{ $t('address.info.type_suspended') }}</span>
                                </ui-link>
                                <div v-else class="card-row__value-tag card-row__value-tag-gray">
                                    <span>{{ $t('address.info.type_inactive') }}</span>
                                </div>
                                <ui-tooltip
                                    v-bind:value="$t('address.info.state')"
                                    v-bind:position="'bottom'"
                                    v-if="showTooltip.state"
                                />
                            </div>
                            <div
                                v-if="(wallet?.wallet_type !== null || contractExtendedInfo?.type !== null) && !isUninit"
                                class="card-row__value-tag-item"
                                v-on:mouseover="showTooltip.contract = true"
                                v-on:mouseleave="showTooltip.contract = false"
                            >
                                <div v-if="!wallet.wallet_type && !contractExtendedInfo" class="card-row__value-tag card-row__value-skeleton skeleton">
                                    <span>NNNNN</span>
                                </div>
                                <div v-else-if="!wallet.wallet_type && contractExtendedInfo?.type"
                                    v-on:click="handleContractTypeClick"
                                    class="card-row__value-tag card-row__value-tag-gray"
                                    v-bind:class="{'card-wallet-type' : ['jetton_wallet', 'subscription', 'nft_item', 'nft_collection', 'nominator_pool'].includes(contractExtendedInfo?.type)}"
                                >
                                    {{$t(`address.contract_type.${contractExtendedInfo?.type}`)}}
                                </div>
                                <div v-else-if="!wallet.wallet_type && contractExtendedInfo?.type === null"
                                    class="card-row__value-tag card-row__value-tag-gray"
                                >
                                    {{$t(`address.contract_type.unknown`)}}
                                </div>
                                <div v-else class="card-row__value-tag card-row__value-tag-gray">
                                    <span>{{ wallet.wallet_type }}</span>
                                </div>
                                <div v-if="walletModal" class="card-wallet-type__dropdown">
                                    <div
                                        v-for="(key, index) in contractExtendedInfo.wallet.alternative_addresses"
                                        v-bind:key="key"
                                        class="card-wallet-type__dropdown-item"
                                    >
                                        <span>{{ formatKey(index) }} • {{ shortenAddress(key) }}</span>
                                    </div>
                                </div>
                                <div v-if="walletModal" v-on:click="handleWalletDropdown" class="card-wallet-type__dropdown-overlay"></div>
                                <ui-tooltip
                                    v-bind:value="$t('address.info.contract_type')"
                                    v-bind:position="'bottom'"
                                    v-if="showTooltip.contract && !walletModal"
                                />
                            </div>
                            <div v-if="isScam" class="card-row__value-tag-item">
                                <div class="card-row__value-tag card-row__value-tag-red">
                                    <span>SCAM</span>
                                </div>
                            </div>
                            <div v-if="walletName !== null" class="card-row__value-tag-item">
                                <div v-if="!walletName" class="card-row__value-tag card-row__value-skeleton skeleton">
                                    <span>NNNNN</span>
                                </div>
                                <div v-else-if="openWalletName" class="card-row__value-tag card-row__value-tag-gray-line">
                                    <span>{{ walletNameFull }}</span>
                                </div>
                                <div v-else v-on:click="openWalletName = true" class="card-row__value-tag card-row__value-tag-gray-line">
                                    <span>{{ walletName }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <address-tabs
                v-bind:address="addressDefault"
                v-bind:isActive="isActive"
                v-on:lastActivityUpdate="handleLastActivityUpdate"
            >
                <template v-slot:customFilter>
                    <custom-filter v-if="$store.state.filterShow" />
                </template>
            </address-tabs>

            <ui-modal v-bind:isOpen.sync="showContractTypeModal">
                <!-- Subscription info component -->
                <template v-if="contractExtendedInfo?.type === 'subscription'">
                    <template slot="header">
                        {{$t('address.contract_type.subscription_modal_header')}}
                    </template>
                    <contract-widget-subscription
                        v-bind:wallet="contractExtendedInfo?.subscription.wallet"
                        v-bind:beneficiary="contractExtendedInfo?.subscription.beneficiary"
                        v-bind:amount="contractExtendedInfo?.subscription.amount"
                        v-bind:period="contractExtendedInfo?.subscription.period"
                        v-bind:startTime="contractExtendedInfo?.subscription.start_time"
                        v-bind:lastPaymentTime="contractExtendedInfo?.subscription.last_payment_time"
                        v-bind:subscriptionId="contractExtendedInfo?.subscription.subscription_id.toString()"/>
                </template>
            </ui-modal>

        </section>

        <ui-modal class="qr-modal" v-bind:isOpen.sync="qrModalVisible">
            <ui-qr show-logo v-bind:value="`ton://transfer/${addressCanonical}`" v-bind:size="300"/>
        </ui-modal>

        <div style="display: none">
            <a ref="devExplorerLink" target="_blank">View in Toncoin Explorer</a>
        </div>
    </section>
</template>

<script>
import IconQr from '@img/icons/tonscan/qr-14.svg?inline';
import IconDomain from '@img/icons/tonscan/domain_renew.svg?vue';
import { goToDevExplorerMixin } from '~/mixins';
import { checkAddress } from '~/api';
import UiQr from '~/components/UiQr.vue';
import { formatTons, shrinkAddress } from '~/helpers';
import ContractWidgetSubscription from '~/components/address/ContractWidgetSubscription.vue';
import { getAccountStates, getJettonsByOwnerAddress } from '~/api/toncenterV3';
import ContractInfoDomain from '~/components/address/ContractInfoDomain.vue';
import AddressTabs from './AddressTabs.vue';
import CustomFilter from './Filter/Filter.vue';

export default {
    props: {
        address: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            addressCanonical: this.address,
            contractTypeVisible: true,
            wallet: {},
            lastActivity: undefined,
            qrModalVisible: false,
            contractExtendedInfo: undefined,
            walletNameFull: undefined,
            openWalletName: false,
            walletModal: false,
            addressDefault: this.address,
            showTooltip: {
                state: false,
                contract: false,
            },
            showContractTypeModal: false,
            topJettons: undefined,
            topJettonsEmpty: false,
            isUninit: false,
        };
    },

    computed: {
        addressMeta() {
            return this.$store.getters.getAddressMeta(this.address);
        },
        isScam() {
            return this.addressMeta?.isScam || this.contractExtendedInfo?.meta?.is_scam;
        },
        isSuspended() {
            return this.isUninit ? false : this.contractExtendedInfo?.meta?.is_suspended;
        },
        isActive() {
            return this.wallet?.status && this.wallet.status === 'active';
        },
        walletName() {
            if (!this.openWalletName && this.walletNameFull && this.walletNameFull.length > 20) {
                return this.walletNameFull.slice(0, 8) + '...' + this.walletNameFull.slice(-8);
            }
            return this.walletNameFull;
        },
    },

    watch: {
        $route(newRoute) {
            if (!newRoute.params.dontRefresh) {
                this.loadData();
            }
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        reset() {
            this.wallet = {};
            this.lastActivity = undefined;
            this.qrModalVisible = false;
            this.contractExtendedInfo = undefined;
            this.walletNameFull = undefined;
            this.openWalletName = false;
            this.topJettons = undefined;
            this.isUninit = false;
        },

        async loadData() {
            this.reset();

            const response = await getAccountStates(this.address);
            this.wallet = response?.accounts[0];
            this.walletNameFull = response?.address_book[this.wallet?.address]?.domain || null;

            if (!response?.accounts[0]) {
                // Means that the address is uninitialized
                this.isUninit = true;

                this.wallet = {
                    status: 'uninit',
                    balance: 0,
                };

                this.topJettonsEmpty = true;
                this.presaveUserAddressbook();
                this.addressDefault = this.address;

                return;
            }

            const hardcodedAddresses = [
                'EQDtFpEwcFAEcRe5mLVh2N6C0x-_hJEM7W61_JLnSF74p4q2',
                'UQDtFpEwcFAEcRe5mLVh2N6C0x-_hJEM7W61_JLnSF74p9dz',
            ];

            if (hardcodedAddresses.includes(this.address)) {
                this.$router.replace(this.$localizeRoute({ name: 'locker', params: { address: hardcodedAddresses[0] } }));
                return;
            }

            this.contractTypeVisible = this.wallet.status === 'active';

            try {
                this.contractExtendedInfo = await checkAddress(this.address).then(Object.freeze);
            } catch {
                this.contractTypeVisible = false;
                this.contractExtendedInfo = { meta: { is_suspended: false } };
            }

            this.addressCanonical = response?.address_book[this.wallet?.address].user_friendly;

            if (this.contractExtendedInfo?.type === 'jetton') {
                this.$router.replace(this.$localizeRoute({ name: 'jetton', params: { address: this.addressCanonical } }));
            }

            if (this.contractExtendedInfo?.type === 'nft_item') {
                this.$router.replace(this.$localizeRoute({ name: 'nft', params: { address: this.addressCanonical } }));
            }

            if (this.contractExtendedInfo?.type === 'vesting_wallet') {
                this.$router.replace(this.$localizeRoute({ name: 'vesting', params: { address: this.addressCanonical } }));
            }

            if (this.contractExtendedInfo?.type === 'nominator_pool_single') {
                this.$router.replace(this.$localizeRoute({ name: 'single_nominator', params: { address: this.addressCanonical } }));
                return;
            }

            if (this.address !== this.addressCanonical) {
                this.$router.replace(this.$localizeRoute({ name: 'address', params: { address: this.addressCanonical, dontRefresh: true } }));
            }

            this.presaveUserAddressbook();
            this.addressDefault = this.addressCanonical;

            try {
                const userJettons = await getJettonsByOwnerAddress(this.address, 20);
                if (userJettons?.jetton_wallets && userJettons.jetton_wallets.length === 0) {
                    this.topJettonsEmpty = true;
                }
                const displayedJettons = userJettons.jetton_wallets.slice(0, 3);
                this.topJettons = {
                    jetton_wallets: displayedJettons,
                    metadata: userJettons.metadata,
                    address_book: userJettons.address_book,
                };
            } catch (e) {
                console.error(e);
            }
        },

        handleLastActivityUpdate(timestamp) {
            this.lastActivity = timestamp;
        },

        metaInfo() {
            return {
                title: this.$t('address.meta.title', { address: this.addressCanonical }),
                meta: [{
                    name: 'description',
                    content: this.$t('address.meta.description', { address: this.addressCanonical }),
                }],
            };
        },

        handleWalletDropdown() {
            this.walletModal = !this.walletModal;
            this.showTooltip.contract = false;
        },

        handleContractTypeClick() {
            const contract = this.contractExtendedInfo;
            const contractType = this.contractExtendedInfo?.type;

            switch (contractType) {
                case 'subscription':
                    this.showContractTypeModal = true;
                    break;
                case 'nft_item':
                    this.$router.push(this.$localizeRoute({ name: 'nft', params: { address: contract?.nft_item?.item_address } }));
                    break;
                case 'nft_collection':
                    this.$router.push(this.$localizeRoute({ name: 'nft', params: { address: this.address } }));
                    break;
                case 'jetton_wallet':
                    this.$router.push(this.$localizeRoute({ name: 'jetton', params: { address: contract?.jetton_wallet?.jetton_address } }));
                    break;
                case 'nominator_pool':
                    this.$router.push(this.$localizeRoute({ name: 'nominator', params: { address: this.address } }));
                    break;
                default:
                    break;
            }
        },

        shortenAddress(address) {
            return shrinkAddress(address);
        },

        formatKey(key) {
            const match = key.match(/wallet_v(\d+)(?:_r(\d+))?(?:_(\w+))?/);
            if (match) {
                const version = match[1];
                const revision = match[2] ? `r${match[2]}` : '';
                const label = match[3] ? ` ${match[3]}` : '';
                return `v${version}${revision}${label}`;
            }

            return key;
        },

        topJettonBalance(balance, decimals) {
            const shouldRound = balance > 100000 * 10 ** decimals;
            return formatTons(balance, decimals, shouldRound);
        },

        presaveUserAddressbook() {
            const payload = {
                address: this.addressCanonical,
                name: this.addressMeta?.name || this.walletName,
                type: 'address',
            };
            this.$store.dispatch('presaveAddressbookItem', payload);
        },
    },

    components: {
        IconDomain,
        ContractInfoDomain,
        ContractWidgetSubscription,
        UiQr,
        AddressTabs,
        IconQr,
        CustomFilter,
    },

    mixins: [goToDevExplorerMixin],
};
</script>

<style lang="scss">
.card-row {
    &__name-tags {
        flex-shrink: 0;
        padding-right: 2ex;
        white-space: nowrap;
        width: 100px;
        color: var(--body-muted-text-color);
        margin-top: 1px;
    }

    &__value-tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
    }

    &__value-tag-item {
        position: relative;
    }

    &__value-tag {
        font-weight: 500;
        user-select: none;
        padding: 2px 9px;
        border-radius: 5px;
        border: 1px solid;
        position: relative;
        font-size: 12px;
    }

    &__value-skeleton {
        border: 0;
        padding: 3px 9px;
    }

    &__value-tag-green {
        border-color: transparent;
        background: var(--address-tag-green-background);
        color: var(--address-tag-green-color);
    }

    &__value-tag-blue {
        border-color: var(--address-tag-blue-background);
        background: var(--address-tag-blue-background);
        color: var(--address-tag-blue-color);
    }

    &__value-tag-orange {
        border-color: var(--address-tag-orange-background);
        background: var(--address-tag-orange-background);
        color: var(--address-tag-orange-color);
    }

    &__value-tag-red {
        border-color: var(--address-tag-red-border);
        background: var(--address-tag-red-background);
        color: var(--address-tag-red-color);
    }

    &__value-tag-gray {
        border-color: transparent;
        background: var(--address-tag-gray-background);
        color: var(--address-tag-gray-color);
    }

    &__value-tag-gray-line {
        background: transparent;
        border-color: var(--address-tag-gray-border);
        color: var(--body-text-color);
        user-select: all;
    }
}

.card-wallet-type {
    cursor: pointer;
    position: relative;

    &__dropdown {
        user-select: none;
        position: absolute;
        top: 30px;
        left: 50%;
        background-color: var(--card-background);
        z-index: 1000;
        border: 1px solid var(--card-row-separator);
        border-radius: 5px;
        white-space: nowrap;
        transform: translate(-50%, 0%);
    }

    &__dropdown-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 999;
    }

    &__dropdown-item {
        padding: 7px 10px;
        border-bottom: 1px solid var(--card-row-separator);
    }

    &__dropdown-item:last-child {
        border-bottom: 0;
    }
}

.top-user-jettons {
    display: flex;
    flex-direction: row;
    gap: 0;
    padding-left: 10px;
    align-items: center;
    margin: -2px 0 -2px 0;

    &__img {
        width: 20px;
        height: 20px;
        margin-left: -10px;
        margin-right: 0;
        border-radius: 50%;
        border: 2px solid var(--card-background);
        box-sizing: content-box;
        background-color: var(--card-background);
    }

    &__text {
        margin-left: 4px;
    }

    a {
        margin-left: 4px;
    }
}

// @media (max-width: 40px) {
//     .card-wallet-type {
//         &__dropdown {
//             left: 40%;
//         }
//     }
// }

.card-domain-container {
    display: inline-flex;
}

.card-domain-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    padding-left: 4px;
    transform: translateY(1px);
}
</style>
